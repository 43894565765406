import repository from "@/repository/Repository";

export default class OJ0030Service {
	// マニュアルダウンロード
    fncDownload() {
        return repository
            .post("/api/OJ0030C/ManualDownload", null, {
                responseType: "blob",
                timeout: 0,
            })
            .then((response: any) => {
                const userAgent = window.navigator.userAgent.toLowerCase();

                if (response.headers["content-type"] == "application/pdf") {
                    const wUrl = window.URL.createObjectURL(response.data);

                    if (userAgent.indexOf("chrome") != -1) {
                        window.open(wUrl);
                    } else if (userAgent.indexOf("safari") != -1) {
                        window.open(wUrl, "_self");
                    } else {
                        window.open(wUrl);
                    }

                    window.URL.revokeObjectURL(wUrl);
                }
            })
            .catch(() => {
                alert("ファイルのダウンロードが失敗しました。");
                return false;
            });
    }
}