import repository from "@/repository/Repository";

export default class OJ7090Service {
	// ログイン情報取得
	fncGetUserInfo(pParam: any){
		return repository.post("/api/OJ0000C/GetUserInfo", pParam).then((res: any) => res.data);
	}
	// 定型文リスト取得
	fncGetList(pParam: any) {
		return repository.post("/api/OJ7060C/GetList", pParam).then((res: any) => res.data)
	}
	// 定型文マスタ取得
	fncGetShosai(pParam: any) {
		return repository.post("/api/OJ7060C/GetShosai", pParam).then((res: any) => res.data)
	}
	// 定型文マスタ登録
	fncDBToroku(pParam: any) {
		return repository.post("/api/OJ7060C/DBToroku", pParam).then((res: any) => res.data)
	}
	// 定型文マスタ削除
	fncDelete(pParam: any) {
		return repository.post("/api/OJ7060C/DBDelete", pParam).then((res: any) => res.data)
	}
}