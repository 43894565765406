<template>
    <div class="p-component p-inputwrapper date-picker" v-if="types == 'date'">
      <input type="date" :class="['p-inputtext p-component input-datepicker', {'p-filled': fncFilled}, {'calendar-only': calendarOnly}]" @keydown="fncKeyDown($event)"
      v-model="fncConvertDate" @blur="fncOnBlur" :min="dateMin" :max="dateMax" :disabled="disabled" :readonly="readonly" ref="DatePicker"/>
      <i v-if="showClear && modelValue != null && modelValue != ''" class="p-datepicker-clear-icon pi pi-times" @click="fncOnClearClick()"></i>
    </div>
    <div class="p-component p-inputwrapper date-picker" v-else-if="types == 'month'">
      <input type="month" :class="['p-inputtext p-component input-datepicker', {'p-filled': fncFilled}, {'calendar-only': calendarOnly}]" @keydown="fncKeyDown($event)"
      v-model="fncConvertDate" @blur="fncOnBlur" :min="monthMin" :max="monthMax" :disabled="disabled" :readonly="readonly" ref="DatePicker"/>
      <i v-if="showClear && modelValue != null && modelValue != ''" class="p-datepicker-clear-icon pi pi-times" @click="fncOnClearClick()"></i>
    </div>
</template>

<script>
import moment from "moment";
import ZFunc from "@/service/ZFunc";

export default {
  emits: ["update:modelValue", "change"],
  props: {
    modelValue: { type: String, default: "" },
    showClear: { type: Boolean, default: false},
    types: { type: String, default: "date" },
    dateMin: { type: String, default: "1900-01-01" },
    dateMax: { type: String, default: "9999-12-31" },
    monthMin: { type: String, default: "1900-01" },
    monthMax: { type: String, default: "9999-12" },
    disabled: { type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    calendarOnly: {type: Boolean, default: false},
  },
    data() {
      return {
          mDATETX: "",
      };
  },
  ZF: null,
  mFORXIN: "",
  mFORDSP: "",

  created() {
    this.ZF = new ZFunc();
    switch (this.types) {
      case "date":
        this.mFORXIN = "YYYYMMDD";
        this.mFORDSP = "YYYY-MM-DD";
        break;
      case "month":
        this.mFORXIN = "YYYYMM";
        this.mFORDSP = "YYYY-MM";
        break;
    }
  },
  methods: {
    // DatePickerを離れた際の処理
    fncOnBlur() {
      let wRESULT = "";
      if(this.$refs.DatePicker.value.length <= 0) {
        wRESULT = "";
        this.$refs.DatePicker.value = "";
      } else {
        let wVALUDT = moment(this.$refs.DatePicker.value);
        let wMAXXDT;
        let wMINXDT;

        switch (this.types) {
          case "date":
            wMAXXDT = moment(this.dateMax);
            wMINXDT = moment(this.dateMin);
            break;
          case "month":
            wMAXXDT = moment(this.monthMax);
            wMINXDT = moment(this.monthMin);
            break;
        }

        if (wVALUDT.isAfter(wMAXXDT) == true) {
          wRESULT = wMAXXDT.format(this.mFORXIN);
        } else if (wVALUDT.isBefore(wMINXDT) == true) {
          wRESULT = wMINXDT.format(this.mFORXIN);
        } else {
          wRESULT = wVALUDT.format(this.mFORXIN);
        }
      }
      this.$refs.DatePicker.value = this.fncConvertDate;
      this.$emit("blur");
      this.$emit("update:modelValue", wRESULT);
    },
    fncOnClearClick() {
      this.$refs.DatePicker.value = "";
      this.$emit("update:modelValue", "");
      this.$emit("change");
    },
    fncKeyDown(wEvent) {
      if (this.readonly == true) {
        wEvent.preventDefault();
      }
    },
  },
  computed: {
    fncFilled() {
      return (this.modelValue != null && this.modelValue.toString().length > 0);
    },
    // 表示日付
    fncConvertDate: {
      get() {
        let wRESULT = "";
        if (this.modelValue.length <= 0) {
          wRESULT = "";
        } else {
          wRESULT = moment(this.modelValue).format(this.mFORDSP);
        }
        return wRESULT;
      },
      set(value) {
        let wRESULT = "";
        wRESULT = moment(value).format(this.mFORXIN);
        this.$emit("update:modelValue", wRESULT);
        this.$emit("change");
      },
    },
  },
};
</script>

<style lang="scss">
.date-picker {
  position:relative;

  ::-webkit-calendar-picker-indicator { 
    cursor: pointer;
    border: none;
    padding-right: 0.1em;
    background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2215%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%236c757d%22%20d%3D%22M20%203h-1V1h-2v2H7V1H5v2H4c-1.1%200-2%20.9-2%202v16c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2V5c0-1.1-.9-2-2-2zm0%2018H4V8h16v13z%22%2F%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%2F%3E%3C%2Fsvg%3E');
  }

  .input-datepicker {
    //height: 35px;
    background-color:transparent;
    color:inherit;
  }

  .p-datepicker-clear-icon {
      position: absolute;
      top: 50%;
      margin-top: -.5rem;
      color: #6c757d;
      right: 2.457rem;
  }
}

.calendar-only{
  padding: 0px !important;
  height: 37px !important;
  width: 37px !important;
  background: #2196F3 !important;
  border: 1px solid #2196F3 !important;
}
.calendar-only::-webkit-datetime-edit { 
  display: none !important;
}
.calendar-only::-webkit-calendar-picker-indicator{
  display: block;
  margin: 0px !important;
  padding: 7px 9px 7px 9px !important;
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2215%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M20%203h-1V1h-2v2H7V1H5v2H4c-1.1%200-2%20.9-2%202v16c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2V5c0-1.1-.9-2-2-2zm0%2018H4V8h16v13z%22%2F%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%2F%3E%3C%2Fsvg%3E%0A') !important;
}

</style>