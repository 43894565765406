
import OJ7040S from "@/service/OJ7040Service";
import ZFunc from "@/service/ZFunc";
import { Vue } from "vue-class-component";
import zCONST from "@/const";
import { Core as YubinBangoCore } from "yubinbango-core2";

export default class OJ7041F extends Vue {
    ZF: ZFunc = new ZFunc();
    OJ7040S: OJ7040S = new OJ7040S();

    mKengenList: any[] = [];
    mShosaiData: any = {};

    mAREAKY: any = 0;        // エリア
    mMODEXX: number = zCONST.cMODE_DISP;
    mUSERKY: number = 0;
    mSAJYKY: number = 0;
    mDispDetail: boolean = false;

    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt: number = 0;

    mMAPiframe: string = "";

    mInputMsg: any = "※半角文字のみで入力してください。";
    mInputNumberMsg: any = "※番号を正しく入力してください。";
    mSearchMsg: any = "検索結果が見つかりません。";

    mZIPXCDMsgfl: boolean = false;
    mSearchMsgfl: boolean = false;
    mTELXNOMsgfl: boolean = false;
    mFAXXNOMsgfl: boolean = false;
    mHPADDRMsgfl: boolean = false;
    mTELXNONumMsgfl: boolean = false;
    mFAXXNONumMsgfl: boolean = false;
    mMapMsgfl: boolean = false;

    $cookies: any;
    onSuccess = (data: any) => { console.log(data); /* ここに返却時処理を書く */};

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mMODEXX = data.MODEXX;
        this.mUSERKY = data.USERKY;
        this.mSAJYKY = data.SAJYKY;

        this.mMapMsgfl = false;

        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;
        // 初期データ取得
        this.fncGetShosai();
    }

    // 閉じるボタン
    fncClose() {
        this.mMessage = [];
        this.mDispDetail = false;
    }

    // 中止ボタン
    fncCancel() {
        this.mMODEXX = zCONST.cMODE_DISP;
        // 初期データ取得
        this.fncGetShosai();
    }

    // 修正ボタン
    fncUpdate() {
        this.mMODEXX = zCONST.cMODE_UPD;
    }

    // 登録ボタン
    fncDBToroku() {
        if (this.fncFldCheck() == false){
                return;
        }
        if (confirm("登録します。よろしいですか?") == true) {
            let wParam = { 
                 MODEXX: this.mMODEXX
                ,shosaiData: this.mShosaiData
            };
            // 登録処理
            this.OJ7040S.fncDBToroku(wParam).then((data: any) => {
                alert("登録しました。");
                // ダイアログを閉じる
                this.mDispDetail = false;
                // 成功後の後処理を行う。
                this.onSuccess(data);
            });
        }
    }

    // 削除ボタン
    fncDBDelete() {
        if (confirm("削除します。よろしいですか?") == true) {
            // 削除処理
            this.OJ7040S.fncDelete(this.mShosaiData.SAJYKY).then((data: any) => {
                alert("削除しました。");
                // ダイアログを閉じる
                this.mDispDetail = false;
                // 成功後の後処理を行う。
                this.onSuccess(data);
            });
        }
    }

    // 初期データ取得
    fncGetShosai(){
        let wSaijyoParam: {SAJYKY?: number, MODEXX?: number} = {};
        wSaijyoParam.SAJYKY = this.mSAJYKY;
        wSaijyoParam.MODEXX = this.mMODEXX;
        this.OJ7040S.fncGetShosai(wSaijyoParam).then((data: any) => {
            this.mShosaiData = data.shosaiData;
        });
    }

    // マップ作成
    fncMapCreate(){
        let ADDRE1 = this.mShosaiData.ADDRE1;
        let ADDRE2 = this.mShosaiData.ADDRE2;

        this.mShosaiData.MAPURL = 'http://maps.google.co.jp/maps?q='+ ADDRE1 + ADDRE2 + '&z=18';

        // this.mMapMsgfl = false;
        // if(ADDRE1 == ""){
        //     this.mMapMsgfl = true;
        //     this.mMAPiframe = "";
        // }else{
        //     this.mMAPiframe = '<iframe src="https://maps.google.co.jp/maps?output=embed&q=' + ADDRE1 + ADDRE2 + '&z=18&t=e"></iframe>';
        // }
    }

    // マップの表示(別画面)
    fncMapShow(){
        window.open(this.mShosaiData.MAPURL, "_blank");
    }

    // 半角チェック
    fncCheckHankaku(event: InputEvent) {
        if (!(event.target instanceof HTMLInputElement)) {
            return;
        }
        let wTargetValue = event.target.value;
        let wTargetId = event.target.id;

        // 全角が含まれていたら
        if(wTargetValue.match(/[^\x01-\x7E\xA1-\xDF]+/)){

            wTargetValue = wTargetValue.replace(/[^\x01-\x7E\xA1-\xDF]+/g, "");
            event.target.value = wTargetValue;

            if(wTargetId == 'zipxcd'){
                this.mShosaiData.ZIPXCD = wTargetValue;
                this.mZIPXCDMsgfl = true;
            }
            if(wTargetId == 'telxno'){
                this.mShosaiData.TELXNO = wTargetValue;
                this.mTELXNOMsgfl = true;
            }
            if(wTargetId == 'faxxno'){
                this.mShosaiData.FAXXNO = wTargetValue;
                this.mFAXXNOMsgfl = true;
            }
            if(wTargetId == 'hpaddr'){
                this.mShosaiData.HPADDR = wTargetValue;
                this.mHPADDRMsgfl = true;
            }
        }else{
            if(wTargetId == 'zipxcd'){
                this.mZIPXCDMsgfl = false;
            }
            if(wTargetId == 'telxno'){
                this.mTELXNOMsgfl = false;
            }
            if(wTargetId == 'faxxno'){
                this.mFAXXNOMsgfl = false;
            }
            if(wTargetId == 'hpaddr'){
                this.mHPADDRMsgfl = false;
            }
        }
        this.fncCheckNumber(event);
    }

    //郵便番号の文字列"000-0000"に変換
    fncChangeZIPXCD(pTarget:any) {
        let wZIPXCD: string;
        let wTargetZIPXCD = "";
        let wLen: number;

        wZIPXCD = pTarget.trim();
        wLen = this.ZF.zByteLength(wZIPXCD);

        if (wZIPXCD == "") {
            pTarget = wTargetZIPXCD;
            this.mShosaiData.ADDRE1 = "";
            this.mSearchMsgfl = false;
            return;
        }

        if (wLen >= 4) {
            if (wZIPXCD.substring(3, 4) == "-") {
                wZIPXCD = wZIPXCD.replace("-", "");
            }
        }
        wLen = this.ZF.zByteLength(wZIPXCD);
        if (wLen < 8) {
            wZIPXCD = wZIPXCD.padEnd(7, "0");
            if (isNaN(Number(wZIPXCD)) == false) {
                wTargetZIPXCD =
                    wZIPXCD.substring(0, 3) + "-" + wZIPXCD.substring(3, 7);
            }
        }
        this.mShosaiData.ZIPXCD = wTargetZIPXCD;
        this.fncSearchYubin();
    }
    
    // 郵便番号自動検索
    fncSearchYubin(){
        new YubinBangoCore(String(this.mShosaiData.ZIPXCD).replace("-",""),(data: any) =>
        {
            this.mSearchMsgfl = false;
            if(data.region != ""){
                this.mShosaiData.ADDRE1 = data.region + data.locality + data.street;
            }else{
                this.mShosaiData.ADDRE1 = "";
                this.mSearchMsgfl = true;
            }
        })
    }

    // 電話・FAX番号入力チェック
    fncCheckNumber(event: InputEvent){
        if (!(event.target instanceof HTMLInputElement)) {
            return;
        }
        let wTargetNumber = event.target.value;
        let wTargetId = event.target.id;

        this.mTELXNONumMsgfl = false;
        this.mFAXXNONumMsgfl = false;
        if(wTargetNumber.length <= 0){
            return;
        }

        if(wTargetNumber.indexOf("-") < 0){
            if(wTargetNumber.trim() != ""){
                if(wTargetId == 'telxno'){
                    this.mTELXNONumMsgfl = true;
                }

                if(wTargetId == 'faxxno'){
                    this.mFAXXNONumMsgfl = true;
                }
            }
        }
    }

    //電話番号の文字列"-"を追加
    fncChangeNumber(pTarget:any,pChange: string) {
        let wNUMBER: string;
        let wTargetNumber = "";
        let wLen: number;

        wNUMBER = pTarget.trim();
        if (wNUMBER == "") {
            return;
        }
        wTargetNumber = wNUMBER;

        wLen = this.ZF.zByteLength(wNUMBER);
        if (wNUMBER.indexOf("-") > 0) {
            wNUMBER = wNUMBER.replaceAll("-", "");
        }

        wLen = this.ZF.zByteLength(wNUMBER);
        if(wLen == 10){
            wTargetNumber = wNUMBER.substring(0, 4) + "-" + wNUMBER.substring(4, 6) + "-" + wNUMBER.substring(6, 10);
        }
        if(wLen == 11){
            wTargetNumber = wNUMBER.substring(0, 3) + "-" + wNUMBER.substring(3, 7) + "-" + wNUMBER.substring(7, 11);
        }

        if(pChange == "TELXNO")
        {
            this.mShosaiData.TELXNO = wTargetNumber;
            if(wTargetNumber.indexOf("-") > 0){
                this.mTELXNONumMsgfl = false;
            }
        }
        else if(pChange == "FAXXNO")
        {
            this.mShosaiData.FAXXNO = wTargetNumber;
            if(wTargetNumber.indexOf("-") > 0){
                this.mFAXXNONumMsgfl = false;
            }
        }
    }

    // 入力項目チェック
    fncFldCheck() {
        this.mMessage = [];
        if (String(this.mShosaiData.SAJYNM).trim() == "") {
            this.mMessage.push({severity: "warn", content: "斎場名を入力してください", id: this.mMessageCnt++});
        }
        if (String(this.mShosaiData.ADDRE1).trim() == "") {
            this.mMessage.push({severity: "warn", content: "住所を入力してください", id: this.mMessageCnt++});
        }
        if(this.mZIPXCDMsgfl == true || this.mSearchMsgfl == true || this.mTELXNOMsgfl == true ||
           this.mFAXXNOMsgfl == true || this.mHPADDRMsgfl == true || this.mTELXNONumMsgfl == true || this.mFAXXNONumMsgfl == true){
            this.mMessage.push({severity: "warn", content: "入力内容を確認してください", id: this.mMessageCnt++});
        }

        if(this.mMessage.length > 0){
            return false;
        }else{
            return true;
        }
    }
}
