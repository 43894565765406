
import { Vue } from "vue-class-component";
import OJ1010S from "@/service/OJ1010Service";
import ZFunc from "@/service/ZFunc";
import zCONST from "@/const";

export default class OJ1013F extends Vue {
    OJ1010S: OJ1010S = new OJ1010S();
    ZF: ZFunc = new ZFunc();

    mDispDetail = false;

    // 変数定義
    mOKYAKY: number = 0;
    mATENAX: any = "";

    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt = 0;

    $cookies: any;

    onSuccess = (data: any) => {
        console.log(data); /* ここに返却時処理を書く */
    };

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mOKYAKY = data.OKYAKY;
        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;
        this.mMessage = [];
        this.mATENAX = "";
    }

    // PDF出力
    fncExport() {
        const wParam = {
            OKYAKY: this.mOKYAKY
            ,ATENAX: this.mATENAX
            ,FILENM: "新聞社用.pdf"
        };

        this.OJ1010S.fncExport(wParam).then((data: any) => {});
    }

    // 閉じるボタン
    fncClose() {
        this.mDispDetail = false;
    }
}
