// このプログラムで使用するグローバス定数を定義
//
// Object.freezeを使用しないと外部で値を書き換えることが出来る
// Object.freezeはネストしたオブジェクトまで見ないので、Objectの中でObjectを宣言する場合、宣言するObjectにもObject.freezeをつける
export default Object.freeze({
    // レスポンス結果
    cKEY_RESULT: "result",
    cKEY_ALERTS: "alerts",
    cKEY_MESSAGE: "message",

    //アラート
    cALERTS_SUCCESS: "success",
    cALERTS_INFO: "info",
    cALERTS_WARNING: "warning",
    cALERTS_DANGER: "danger",

    // アップロードファイルサイズ(20MBまで)
    cUPLOAD_FILE_SIZE: "20MB",
    cUPLOAD_FILE_SIZE_BYTE: 20 * 1024 * 1024,

    // メッセージ
    // エラー
    cERR_NOT_GET: "の取得に失敗しました。",
    cERR_DB_DELETE: "ＤＢ削除でエラーが発生しました。",
    cERR_DB_TOROKU: "ＤＢ登録でエラーが発生しました。",
    cERR_FILE_UPLOAD: "ファイルアップロード中にエラーが発生しました。",
    cERR_BLANK: "を入力してください。",

    // 権限
    // システム管理者
    cKENGEN_KANRI: 1,
    // パワーユーザー
    cKENGEN_POWER_USER: 2,
    // エリア管理者
    cKENGEN_AREA_KANRI: 11,
    // 得意先管理者
    cKENGEN_TOKUI_KANRI: 21,
     // 得意先ユーザー
    cKENGEN_USER: 22,

    /// <summary>処理ﾓｰﾄﾞ:表示</summary>
    cMODE_DISP: 1,
    /// <summary>処理ﾓｰﾄﾞ:追加/新規</summary>
    cMODE_ADD: 2,
    /// <summary>処理ﾓｰﾄﾞ:修正</summary>
    cMODE_UPD: 3,
    /// <summary>処理ﾓｰﾄﾞ:削除</summary>
    cMODE_DEL: 4,
});