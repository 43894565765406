
import OJ1010S from "@/service/OJ1010Service";
import ZFunc from "@/service/ZFunc";
import { Vue } from "vue-class-component";
import zCONST from "@/const";


export default class OJ1012F extends Vue {
    ZF: ZFunc = new ZFunc();
    OJ1010S: OJ1010S = new OJ1010S();

    mShosaiData: any = {};
    mOKYAKY: any = 0;
    mAREAKY: any = 0;        // エリア
    mMODEXX: number = zCONST.cMODE_DISP;
    mUSERKY: number = 0;
    mDispDetail: boolean = false;
    mKOKADT: any = "";      //掲載日　システム日付

    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt: number = 0;
    mUploadfl: boolean = false;
    mUploadMsg: string = "";

    $cookies: any;
    onSuccess = (data: any) => { console.log(data); /* ここに返却時処理を書く */};

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mMODEXX = data.MODEXX;
        this.mOKYAKY = data.OKYAKY;
        this.mAREAKY = data.AREAKY;
        this.mUSERKY = this.$cookies.get("USERKY")
        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;

        // 詳細データ取得
        this.funcGetShosai();
    }

    // 戻るボタン
    fncBack() {
        this.mDispDetail = false;
    }

    // 公開ボタン
    fncUpload() {
        if (confirm("おくやみ情報を公開します。よろしいですか?") == true) {
            document.getElementById("UpMsgBox")?.scrollIntoView({
                behavior: "smooth",
                block: 'start'
            })
            this.mUploadMsg = "ページを作成しています。";
            this.mUploadfl = true;
            let wParam = { 
                 OKYAKY: this.mOKYAKY
                ,AREAKY: this.mAREAKY
                ,USERKY: this.mUSERKY
                ,KOKAKB: 1
            };
            // 登録処理
            this.OJ1010S.fncPageCreate(wParam).then((data: any) => {
                this.mUploadMsg = "アップロード中です。少々お待ちください...";
                this.OJ1010S.fncPageUpload(wParam).then((data: any) => {
                    this.mUploadfl = false;                
                    alert("公開しました。");
                    // ダイアログを閉じる
                    this.mDispDetail = false;
                    // 成功後の後処理を行う。
                    this.onSuccess(data);
                })
            });
        }
    }

    // 詳細データ取得
    funcGetShosai(){
        let wParam = { 
            OKYAKY: this.mOKYAKY
        };
        this.OJ1010S.fncGetShosai(wParam).then((data: any) => {
            this.mShosaiData = data.shosaiData;
            this.mKOKADT = data.SYSXDT;
        });
    }
}
