
import AuthService from "@/auth/AuthService";
import OJ7040S from "@/service/OJ7040Service";
import ZFunc from "@/service/ZFunc";
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ7091F extends Vue {

    // クラス定義
    AuthS: AuthService = new AuthService();
    OJ7040S: OJ7040S = new OJ7040S();
    ZF: ZFunc = new ZFunc();

    // 検索フィールド変数
    mRefSAJYNM: any = "";       // 斎場名

    mUSERKY: any = 0;
    mTOKUKY: any = 0;

    // 一覧フィールド変数
    mDgList: any[] = [];            // データグリッドソース
    loadingFlg: boolean = false;    // ロード中フラグ
    mDispDetail: boolean = false;

    // 返却
    mReturnData: any = {};
    
    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt: number = 0;

    $cookies: any;

    // 返却処理
    onSuccess = (data: any) => {
        return data;
    };

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mUSERKY = this.$cookies.get("USERKY");
        console.log("ユーザー",this.mUSERKY);
        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;
        //ログイン情報取得
        let wParam = { USERKY: this.mUSERKY };
        this.OJ7040S.fncGetUserInfo(wParam).then((data: any) => {
            this.mTOKUKY = data.TOKUKY;
            this.fncGetList();
        });
    }

    // 選択ボタン
    fncSelect(pSelectData: any) {
        this.mReturnData = pSelectData;
        this.onSuccess(this.mReturnData);
        this.mDispDetail = false;
    }

    // 閉じるボタン
    fncClose() {
        this.mDispDetail = false;
    }

    // 斎場リスト取得処理
    fncGetList(): void {
        let wParam = { 
             TOKUKY: this.mTOKUKY
            ,SAJYNM: this.mRefSAJYNM
        };
        this.loadingFlg = true;
        this.OJ7040S.fncGetSanshoList(wParam).then((data: any) => {
            this.mDgList = data.dgList;
        }).finally(() => {this.loadingFlg = false;});
    }

}
