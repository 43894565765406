
import OJ7090S from "@/service/OJ7090Service";
import ZFunc from "@/service/ZFunc";
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ7091F extends Vue {
    ZF: ZFunc = new ZFunc();
    OJ7090S: OJ7090S = new OJ7090S();

    mKengenList: any[] = [];
    mShosaiData: any = {};

    mAREAKY: any = 0;        // エリアキー
    mTOKUKY: any = 0;        // 得意先キー
    mMODEXX: number = zCONST.cMODE_DISP;
    mUSERKY: number = 0;
    mDispDetail: boolean = false;

    // パスワード
    mPassCng: boolean = false;
    mPasswd: string = "";
    mPasswd2: string = "";

    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt: number = 0;

    $cookies: any;

    onSuccess = (data: any) => { console.log(data); /* ここに返却時処理を書く */};

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mMODEXX = data.MODEXX;
        this.mUSERKY = data.USERKY;
        this.mAREAKY = data.AREAKY;
        this.mTOKUKY = data.TOKUKY;

        //変数初期化
        this.mPassCng = false;
        this.mPasswd = "";
        this.mPasswd2 = "";
        this.mMessage = [];
        this.mMessageCnt = 0;

        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;
        // 初期データ取得
        this.funcGetShosai();

        console.log("パスワード：" + this.mPasswd);
    }

    // 閉じるボタン
    fncClose() {
        this.mDispDetail = false;
    }

    // 中止ボタン
    fncCancel() {
        this.mMODEXX = zCONST.cMODE_DISP;
        //変数初期化
        this.mPassCng = false;
        this.mPasswd = "";
        this.mPasswd2 = "";
        this.mMessage = [];
        this.mMessageCnt = 0;
        // 初期データ取得
        this.funcGetShosai();
    }

    // 修正ボタン
    fncUpdate() {
        this.mMODEXX = zCONST.cMODE_UPD;
    }

    // 登録ボタン
    fncDBToroku() {
        if (this.fncFldCheck() == false){
                return;
        }
        if (confirm("登録します。よろしいですか?") == true) {
            this.mShosaiData.AREAKY = this.mAREAKY;
            this.mShosaiData.TOKUKY = this.mTOKUKY;
            this.mShosaiData.PASSWD = this.mPasswd;
            let wPASCNG = 0;
            if(this.mPassCng == true){
                wPASCNG = 1;
            }
            let wParam = { 
                MODEXX: this.mMODEXX
                ,PASCNG: wPASCNG
                ,shosaiData: this.mShosaiData
            };
            // 登録処理
            this.OJ7090S.fncDBToroku(wParam).then((data: any) => {
                if (data[zCONST.cKEY_RESULT] == true) {
                    alert("登録しました。");
                    // ダイアログを閉じる
                    this.mDispDetail = false;
                    // 成功後の後処理を行う。
                    this.onSuccess(data);
                }else{
                    this.mMessage = [];
                    this.mMessage.push({severity: "warn", content: data[zCONST.cKEY_MESSAGE], id: this.mMessageCnt++});
                }
            });
        }
    }

    // 削除ボタン
    fncDBDelete() {
        this.mMessage = [];
        if (confirm("削除します。よろしいですか?") == true) {
            // 登録処理
            this.OJ7090S.fncDeleteUserms(this.mShosaiData.USERKY).then((data: any) => {
                if (data.result === false) {
                    for (const msg of data.message) {
                        this.mMessage.push({severity: "warn", content: msg, id: this.mMessageCnt++})
                    }
                    return;
                }

                alert("削除しました。");
                // ダイアログを閉じる
                this.mDispDetail = false;
                // 成功後の後処理を行う。
                this.onSuccess(data);
            });
        }
    }

    // 初期データ取得
    funcGetShosai(){
        let wUserParam: {USERKY?: number, MODEXX?: number, KENGEN?:Number} = {};
        wUserParam.USERKY = this.mUSERKY;
        wUserParam.MODEXX = this.mMODEXX;
        wUserParam.KENGEN = Number(this.$cookies.get("KENGEN"));

        this.OJ7090S.fncInit({}).then((data: any) => {
            this.mKengenList = data.KengenList;
        }).then((res: any) => {
            this.OJ7090S.fncGetShosai(wUserParam).then((data: any) => {
                this.mShosaiData = data.shosaiData;
            });
        })
    }

    // 半角英数字入力制限
    fncInputHankaku(event: InputEvent) {
        event.preventDefault();
        // 半角英数字のみ入力
        if (!(event.target instanceof HTMLInputElement)) {
            return;
        }
        let wTempValue = event.target.value;
        let wTargetId = event.target.id;
        if (wTempValue) {
            if (wTargetId == "passwd" || wTargetId == "passwd2") {
                wTempValue = wTempValue.replace(/[^0-9a-zA-Z @!#$%&=~/*\-+_]/g, "");
            } else{
                wTempValue = wTempValue.replace(/[^0-9a-zA-Z @!#$%&=~/*\-+_\.]/g, "");
            }
            event.target.value = wTempValue;
            if (wTargetId == "userid"){
                this.mShosaiData.USERID = wTempValue;
            }
            if (wTargetId == "mailad"){
                this.mShosaiData.MAILAD = wTempValue;
            }
            if (wTargetId == "passwd"){
                this.mPasswd = wTempValue;
            }
            if (wTargetId == "passwd2"){
                this.mPasswd2 = wTempValue;
            } 
        }
    }

    // 入力項目チェック
    fncFldCheck() {
    this.mMessage = [];
        if (String(this.mShosaiData.USERID).trim() == "") {
            this.mMessage.push({severity: "warn", content: "IDを入力してください", id: this.mMessageCnt++});
        }
        if (String(this.mShosaiData.USERNM).trim() == "") {
            this.mMessage.push({severity: "warn", content: "ユーザ名を入力してください", id: this.mMessageCnt++});
        }
        if (Number(this.mShosaiData.KENGEN) == null || Number(this.mShosaiData.KENGEN) == 0) {
            this.mMessage.push({severity: "warn", content: "権限を選択してください", id: this.mMessageCnt++});
        }
        if(this.mPassCng == true || this.mMODEXX == zCONST.cMODE_ADD){
            if (String(this.mPasswd).trim() == "") {
                this.mMessage.push({severity: "warn", content: "パスワードを入力してください", id: this.mMessageCnt++});
            }
            else{
                if(String(this.mPasswd).trim() ==
                        String(this.mShosaiData.USERID).trim()){
                            this.mMessage.push({severity: "warn", content: "パスワードにIDは使用できません", id: this.mMessageCnt++});
                }   
                if(this.fncPassCheck(this.mPasswd) == false){
                    this.mMessage.push({severity: "warn", content: "パスワードは半角大文字、半角小文字、数字、記号を含む文字列を入力してください", id: this.mMessageCnt++});
                }
                else if(this.mPasswd.length < 8){
                    this.mMessage.push({severity: "warn", content: "パスワードは8文字以上を入力してください", id: this.mMessageCnt++});
                }
            }
        }
        if (
            String(this.mPasswd).trim() !=
            String(this.mPasswd2).trim()
        ) {
            this.mMessage.push({
                severity: "warn",
                content: "確認用パスワードが一致していません。",
                id: this.mMessageCnt++,
            });
        }
        if(this.mMessage.length > 0){
            return false;
        }else{
            return true;
        }
    }

    //パスワードチェンジ
    fncPassChange(){
        if(this.mPassCng == false){
            this.mPasswd = "";
            this.mPasswd2 = "";
        }
    }

    // パスワード生成
    fncGetPass(){
        let wMoji = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!#$%&=~/*-+" ;
        let wPassChk = false;
        let wResult = "" ;
        while (wPassChk == false){
            //ランダムな文字列の生成
            wResult = "";
            for (let i = 0;  i < 10;  i++) {
                wResult += wMoji.charAt(Math.floor(Math.random() * wMoji.length)) 
            }
            wPassChk = this.fncPassCheck(wResult)
        }
        this.mPasswd =wResult;
    }

     // パスワードチェック
    fncPassCheck(pPasswd:string){
        // 半角小文字
        const w_atz = /[a-z]/;
        if(w_atz.test(pPasswd) == false){
            console.log("小文字NG");
            return false;
        }
        // 半角大文字
        const w_AtZ = /[A-Z]/;
        if(w_AtZ.test(pPasswd) == false){
            console.log("大文字NG");
            return false;
        }
        // 数字
        const w_0t9 = /[0-9]/;
        if(w_0t9.test(pPasswd) == false){
            console.log("数字NG");
            return false;
        }
        // 記号
        var reg = new RegExp(/[!#$%&\*\+\-@~=/]/g);
        if(reg.test(pPasswd) == false) {
            return false;
        }
        return true;
    }
}
