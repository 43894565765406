<template>
    <div ref="container" :class="containerClass" @click="onClick($event)">
        <div class="p-hidden-accessible">
            <input
                ref="focusInput"
                type="text"
                :id="inputId"
                readonly
                :disabled="disabled"
                @focus="onFocus"
                @blur="onBlur"
                @keydown="onKeyDown"
                :tabindex="tabindex"
                aria-haspopup="listbox"
                :aria-expanded="overlayVisible"
                :aria-labelledby="ariaLabelledBy"
            />
        </div>
        <input
            v-if="editable"
            type="text"
            class="p-dropdown-label p-inputtext"
            :readonly="readonly"
            :disabled="disabled"
            @focus="onFocus"
            @blur="onBlur"
            :placeholder="placeholder"
            :value="editableInputValue"
            @input="onEditableInput"
            aria-haspopup="listbox"
            :aria-expanded="overlayVisible"
            :maxlength="maxLength"
        />
        <span v-if="!editable" :class="labelClass">
            <slot name="value" :value="modelValue" :placeholder="placeholder">
                {{ label }}
            </slot>
        </span>
        <i
            v-if="showClear && modelValue != null"
            class="p-dropdown-clear-icon pi pi-times"
            @click="onClearClick($event)"
        ></i>
        <div
            class="p-dropdown-trigger"
            role="button"
            aria-haspopup="listbox"
            :aria-expanded="overlayVisible"
            v-if="readonly == false"
        >
            <span class="p-dropdown-trigger-icon pi pi-chevron-down"></span>
        </div>
        <transition
            name="p-connected-overlay"
            @enter="onOverlayEnter"
            @leave="onOverlayLeave"
        >
            <div
                :ref="overlayRef"
                class="p-dropdown-panel p-component"
                v-if="overlayVisible"
            >
                <div class="p-dropdown-header" v-if="filter">
                    <div class="p-dropdown-filter-container">
                        <input
                            type="text"
                            ref="filterInput"
                            v-model="filterValue"
                            autoComplete="off"
                            class="p-dropdown-filter p-inputtext p-component"
                            :placeholder="filterPlaceholder"
                            @keydown="onFilterKeyDown"
                            @input="onFilterChange"
                        />
                        <span
                            class="p-dropdown-filter-icon pi pi-search"
                        ></span>
                    </div>
                </div>
                <div
                    class="p-dropdown-items-wrapper"
                    :style="{ 'max-height': scrollHeight, 'max-width': sclollWidth }"
                >
                    <ul class="p-dropdown-items" role="listbox">
                        <li
                            v-for="(option, i) of visibleOptions"
                            :class="[
                                'p-dropdown-item',
                                {
                                    'p-highlight': isSelected(option),
                                    'p-disabled': isOptionDisabled(option),
                                },
                            ]"
                            v-ripple
                            :aria-label="getOptionLabel(option)"
                            :key="getOptionRenderKey(option)"
                            @click="onOptionSelect($event, option)"
                            role="option"
                            :aria-selected="isSelected(option)"
                        >
                            <slot name="option" :option="option" :index="i">
                                {{ getOptionLabel(option) }}
                            </slot>
                        </li>
                        <li
                            v-if="
                                filterValue &&
                                (!visibleOptions ||
                                    (visibleOptions &&
                                        visibleOptions.length === 0))
                            "
                            class="p-dropdown-empty-message"
                        >
                            {{ emptyFilterMessage }}
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ConnectedOverlayScrollHandler from "primevue/components/utils/ConnectedOverlayScrollHandler";
import ObjectUtils from "primevue/components/utils/ObjectUtils";
import DomHandler from "primevue/components/utils/DomHandler";
import Ripple from "primevue/components/ripple/Ripple";

export default {
    emits: [
        "update:modelValue",
        "before-show",
        "before-hide",
        "show",
        "hide",
        "change",
        "filter",
    ],
    props: {
        modelValue: null,
        options: Array,
        optionLabel: null,
        optionValue: null,
        optionKana: null,
        optionDisabled: null,
        scrollHeight: {
            type: String,
            default: "200px",
        },
        sclollWidth: {
            type: String,
            default: "auto"
        },
        filter: Boolean,
        filterPlaceholder: String,
        filterLocale: String,
        editable: Boolean,
        placeholder: String,
        disabled: Boolean,
        dataKey: null,
        showClear: Boolean,
        inputId: String,
        tabindex: String,
        ariaLabelledBy: null,
        appendTo: {
            type: String,
            default: null,
        },
        emptyFilterMessage: {
            type: String,
            default: "No results found",
        },

        readonly: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            default: 999,
        },
        overlayMargin: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            focused: false,
            filterValue: null,
            overlayVisible: false,
        };
    },
    outsideClickListener: null,
    outsideScrollListener: null,
    scrollHandler: null,
    resizeListener: null,
    searchTimeout: null,
    currentSearchChar: null,
    previousSearchChar: null,
    searchValue: null,
    overlay: null,
    beforeUnmount() {
        this.restoreAppend();
        this.unbindOutsideClickListener();
        this.unbindResizeListener();

        if (this.scrollHandler) {
            this.scrollHandler.destroy();
            this.scrollHandler = null;
        }
        this.overlay = null;
    },
    methods: {
        getOptionLabel(option) {
            return this.optionLabel
                ? ObjectUtils.resolveFieldData(option, this.optionLabel)
                : option;
        },
        getOptionValue(option) {
            return this.optionValue
                ? ObjectUtils.resolveFieldData(option, this.optionValue)
                : option;
        },
        getOptionKana(option) {
            return this.optionKana
                ? ObjectUtils.resolveFieldData(option, this.optionKana)
                : option;
        },
        getOptionRenderKey(option) {
            return this.dataKey
                ? ObjectUtils.resolveFieldData(option, this.dataKey)
                : this.getOptionLabel(option);
        },
        isOptionDisabled(option) {
            return this.optionDisabled
                ? ObjectUtils.resolveFieldData(option, this.optionDisabled)
                : false;
        },
        getSelectedOption() {
            let selectedOption;

            if (this.modelValue != null && this.options) {
                for (const option of this.options) {
                    if (
                        ObjectUtils.equals(
                            this.modelValue,
                            this.getOptionValue(option),
                            this.equalityKey
                        )
                    ) {
                        selectedOption = option;
                        break;
                    }
                }
            }

            return selectedOption;
        },
        isSelected(option) {
            return ObjectUtils.equals(
                this.modelValue,
                this.getOptionValue(option),
                this.equalityKey
            );
        },
        getSelectedOptionIndex() {
            let selectedOptionIndex = -1;

            if (this.modelValue != null && this.visibleOptions) {
                for (let i = 0; i < this.visibleOptions.length; i++) {
                    if (
                        ObjectUtils.equals(
                            this.modelValue,
                            this.getOptionValue(this.visibleOptions[i]),
                            this.equalityKey
                        )
                    ) {
                        selectedOptionIndex = i;
                        break;
                    }
                }
            }

            return selectedOptionIndex;
        },
        show() {
            this.$emit("before-show");
            this.overlayVisible = true;
        },
        hide() {
            this.$emit("before-hide");
            this.overlayVisible = false;
        },
        onFocus() {
            this.focused = true;
        },
        onBlur() {
            this.focused = false;
        },
        onKeyDown(event) {
            switch (event.which) {
                //down
                case 40:
                    this.onDownKey(event);
                    break;

                //up
                case 38:
                    this.onUpKey(event);
                    break;

                //space
                case 32:
                    if (!this.overlayVisible) {
                        this.show();
                        event.preventDefault();
                    }
                    break;

                //enter and escape
                case 13:
                case 27:
                    if (this.overlayVisible) {
                        this.hide();
                        event.preventDefault();
                    }
                    break;

                //tab
                case 9:
                    this.hide();
                    break;

                default:
                    this.search(event);
                    break;
            }
        },
        onFilterKeyDown(event) {
            switch (event.which) {
                //tab
                case 9:
                    this.hide();
                    break;

                //down
                case 40:
                    this.onDownKey(event);
                    break;

                //up
                case 38:
                    this.onUpKey(event);
                    break;

                //enter and escape
                case 13:
                case 27:
                    this.overlayVisible = false;
                    event.preventDefault();
                    break;

                default:
                    break;
            }
        },
        onDownKey(event) {
            if (this.visibleOptions) {
                if (!this.overlayVisible && event.altKey) {
                    this.show();
                } else {
                    const nextOption = this.findNextOption(
                        this.getSelectedOptionIndex()
                    );

                    if (nextOption) {
                        this.updateModel(
                            event,
                            this.getOptionValue(nextOption)
                        );
                    }
                }
            }

            event.preventDefault();
        },
        onUpKey(event) {
            if (this.visibleOptions) {
                const prevOption = this.findPrevOption(
                    this.getSelectedOptionIndex()
                );

                if (prevOption) {
                    this.updateModel(event, this.getOptionValue(prevOption));
                }
            }

            event.preventDefault();
        },
        findNextOption(index) {
            const i = index + 1;
            if (i === this.visibleOptions.length) {
                return null;
            }

            const option = this.visibleOptions[i];
            if (this.isOptionDisabled(option)) {
                return this.findNextOption(i);
            } else {
                return option;
            }
        },
        findPrevOption(index) {
            const i = index - 1;
            if (i < 0) {
                return null;
            }

            const option = this.visibleOptions[i];
            if (this.isOptionDisabled(option)) {
                return this.findPrevOption(i);
            } else {
                return option;
            }
        },
        onClearClick(event) {
            this.updateModel(event, null);
        },
        onClick(event) {
            if (this.disabled || this.readonly) {
                return;
            }

            if (
                DomHandler.hasClass(event.target, "p-dropdown-clear-icon") ||
                event.target.tagName === "INPUT"
            ) {
                return;
            } else if (!this.overlay || !this.overlay.contains(event.target)) {
                if (this.overlayVisible) {
                    this.hide();
                } else {
                    this.show();
                }

                this.$refs.focusInput.focus();
            }
        },
        onOptionSelect(event, option) {
            const value = this.getOptionValue(option);
            this.updateModel(event, value);
            this.$refs.focusInput.focus();

            setTimeout(() => {
                this.hide();
            }, 200);
        },
        onEditableInput(event) {
            this.$emit("update:modelValue", event.target.value);
        },
        onOverlayEnter() {
            this.overlay.style.zIndex = String(DomHandler.generateZIndex());

            this.appendContainer();
            this.alignOverlay();
            this.bindOutsideClickListener();
            this.bindScrollListener();
            this.bindResizeListener();

            this.$emit("show");
        },
        onOverlayLeave() {
            this.unbindOutsideClickListener();
            this.unbindScrollListener();
            this.unbindResizeListener();
            this.$emit("hide");
            this.overlay = null;
        },
        alignOverlay() {
            if (this.appendTo) {
                DomHandler.absolutePosition(this.overlay, this.$el);
                this.overlay.style.minWidth =
                    DomHandler.getOuterWidth(this.$el) + "px";
            } else {
                this.relativePosition(this.overlay, this.$el);
            }
        },
        updateModel(event, value) {
            this.$emit("update:modelValue", value);
            this.$emit("change", { originalEvent: event, value: value });
        },
        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (
                        this.overlayVisible &&
                        this.overlay &&
                        !this.$el.contains(event.target) &&
                        !this.overlay.contains(event.target)
                    ) {
                        this.hide();
                    }
                };
                document.addEventListener("click", this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener(
                    "click",
                    this.outsideClickListener
                );
                this.outsideClickListener = null;
            }
        },
        bindScrollListener() {
            if (!this.scrollHandler) {
                this.scrollHandler = new ConnectedOverlayScrollHandler(
                    this.$refs.container,
                    () => {
                        if (this.overlayVisible) {
                            this.hide();
                        }
                    }
                );
            }

            if (!this.outsideScrollListener) {
                this.outsideScrollListener = (event) => {
                    const isIPad =
                        /iPad|Macintosh/i.test(navigator.userAgent) &&
                        "ontouchend" in document;
                    if (
                        isIPad == true &&
                        DomHandler.hasClass(
                            document.activeElement,
                            "p-dropdown-filter"
                        ) &&
                        document.activeElement.tagName === "INPUT"
                    ) {
                        window.scrollTop(
                            this.$refs.container.getBoundingClientRect().y
                        );
                        this.overlay.style.top =
                            this.$refs.container.getBoundingClientRect().y +
                            this.$refs.container.offsetHeight +
                            "px";
                        this.overlay.style.left =
                            this.$refs.container.getBoundingClientRect().x +
                            "px";
                        this.overlay.style.display = "block";

                        return;
                    }
                    if (
                        this.overlayVisible &&
                        this.overlay &&
                        !this.$el.contains(event.target) &&
                        !this.overlay.contains(event.target)
                    ) {
                        this.hide();
                    }
                };
                document.addEventListener("scroll", this.outsideScrollListener);
            }

            this.scrollHandler.bindScrollListener();
        },
        unbindScrollListener() {
            if (this.scrollHandler) {
                this.scrollHandler.unbindScrollListener();
            }

            if (this.outsideScrollListener) {
                document.removeEventListener(
                    "scroll",
                    this.outsideScrollListener
                );
                this.outsideScrollListener = null;
            }
        },
        bindResizeListener() {
            if (!this.resizeListener) {
                this.resizeListener = () => {
                    if (this.overlayVisible) {
                        this.hide();
                    }
                };
                window.addEventListener("resize", this.resizeListener);
            }
        },
        unbindResizeListener() {
            if (this.resizeListener) {
                window.removeEventListener("resize", this.resizeListener);
                this.resizeListener = null;
            }
        },
        search(event) {
            if (!this.visibleOptions) {
                return;
            }

            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }

            const char = String.fromCharCode(event.keyCode);
            this.previousSearchChar = this.currentSearchChar;
            this.currentSearchChar = char;

            if (this.previousSearchChar === this.currentSearchChar) {
                this.searchValue = this.currentSearchChar;
            } else {
                this.searchValue = this.searchValue
                    ? this.searchValue + char
                    : char;
            }

            let searchIndex = this.getSelectedOptionIndex();
            const newOption = this.searchOption(++searchIndex);

            if (newOption) {
                this.updateModel(event, this.getOptionValue(newOption));
            }

            this.searchTimeout = setTimeout(() => {
                this.searchValue = null;
            }, 250);
        },
        searchOption(index) {
            let option;

            if (this.searchValue) {
                option = this.searchOptionInRange(
                    index,
                    this.visibleOptions.length
                );

                if (!option) {
                    option = this.searchOptionInRange(0, index);
                }
            }

            return option;
        },
        searchOptionInRange(start, end) {
            for (let i = start; i < end; i++) {
                const opt = this.visibleOptions[i];
                const label = this.getOptionLabel(opt).toLocaleLowerCase(
                    this.filterLocale
                );
                if (
                    label.startsWith(
                        this.searchValue.toLocaleLowerCase(this.filterLocale)
                    )
                ) {
                    return opt;
                }
            }

            return null;
        },
        appendContainer() {
            if (this.appendTo) {
                if (this.appendTo === "body") {
                    document.body.appendChild(this.overlay);
                } else {
                    document
                        .getElementById(this.appendTo)
                        .appendChild(this.overlay);
                }
            }
        },
        restoreAppend() {
            if (this.overlay && this.appendTo) {
                if (this.appendTo === "body") {
                    document.body.removeChild(this.overlay);
                } else {
                    document
                        .getElementById(this.appendTo)
                        .removeChild(this.overlay);
                }
            }
        },
        onFilterChange(event) {
            this.$emit("filter", {
                originalEvent: event,
                value: event.target.value,
            });
            if (this.overlayVisible) {
                this.alignOverlay();
            }
        },
        overlayRef(el) {
            this.overlay = el;
        },
        relativePosition(element, target) {
            const elementDimensions = element.offsetParent
                ? { width: element.offsetWidth, height: element.offsetHeight }
                : DomHandler.getHiddenElementDimensions(element);
            const targetHeight = target.offsetHeight;
            const targetOffset = target.getBoundingClientRect();
            const viewport = DomHandler.getViewport();
            let top, left;

            if (
                targetOffset.top + targetHeight + elementDimensions.height >
                viewport.height - this.overlayMargin
            ) {
                top = -1 * elementDimensions.height;
                element.style.transformOrigin = "bottom";
                if (targetOffset.top + top < 0) {
                    top = -1 * targetOffset.top;
                }
            } else {
                top = targetHeight;
                element.style.transformOrigin = "top";
            }

            if (elementDimensions.width > viewport.width) {
                // element wider then viewport and cannot fit on screen (align at left side of viewport)
                left = targetOffset.left * -1;
            } else if (
                targetOffset.left + elementDimensions.width >
                viewport.width
            ) {
                // element wider then viewport but can be fit on screen (align at right side of viewport)
                left =
                    (targetOffset.left +
                        elementDimensions.width -
                        viewport.width) *
                    -1;
            } else {
                // element fits on screen (align with target)
                left = 0;
            }

            element.style.top = top + "px";
            element.style.left = left + "px";
        },
    },
    computed: {
        visibleOptions() {
            if (this.filterValue && this.filterValue.trim().length > 0)
                return this.options.filter(
                    (option) =>
                        this.getOptionLabel(option)
                            .toLocaleLowerCase(this.filterLocale)
                            .indexOf(
                                this.filterValue.toLocaleLowerCase(
                                    this.filterLocale
                                )
                            ) > -1
                );
            else return this.options;
        },
        containerClass() {
            return [
                "p-dropdown p-component p-inputwrapper",
                {
                    "p-disabled": this.disabled,
                    "p-dropdown-clearable": this.showClear && !this.disabled,
                    "p-focus": this.focused,
                    "p-inputwrapper-filled": this.modelValue,
                    "p-inputwrapper-focus": this.focused,
                },
            ];
        },
        labelClass() {
            return [
                "p-dropdown-label p-inputtext",
                {
                    "p-placeholder": this.label === this.placeholder,
                    "p-dropdown-label-empty":
                        !this.$slots["value"] &&
                        (this.label === "p-emptylabel" ||
                            this.label.length === 0),
                },
            ];
        },
        label() {
            const selectedOption = this.getSelectedOption();
            if (selectedOption) {
                return this.getOptionLabel(selectedOption);
            } else {
                return this.placeholder || "p-emptylabel";
            }
        },
        editableInputValue() {
            const selectedOption = this.getSelectedOption();
            if (selectedOption) {
                return this.getOptionLabel(selectedOption);
            } else {
                return this.modelValue;
            }
        },
        equalityKey() {
            return this.optionValue ? null : this.dataKey;
        },
    },
    directives: {
        ripple: Ripple,
    },
};
</script>

<style>
.p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    user-select: none;
}

.p-dropdown-clear-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
}

.p-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.p-dropdown-label {
    display: block;
    white-space: nowrap;
    overflow: auto;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: clip;
    cursor: pointer;
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
}

.p-dropdown-label::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
}

.p-dropdown-label-empty {
    overflow: hidden;
    visibility: hidden;
}

input.p-dropdown-label {
    cursor: default;
}

.p-dropdown .p-dropdown-panel {
    /*min-width: auto;*/
    min-width: 100%;
}

.p-dropdown-panel {
    /*position: fixed;*/
    position: absolute;
    top: 0;
    left: 0;
}

.p-dropdown-items-wrapper {
    overflow: auto;
}

.p-dropdown-item {
    cursor: pointer;
    font-weight: normal;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    height: 35px;
}

.p-dropdown-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.p-dropdown-filter {
    width: 100%;
}

.p-dropdown-filter-container {
    position: relative;
}

.p-dropdown-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
}

.p-fluid .p-dropdown {
    display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
    width: 1%;
}

.p-disabled,
.p-disabled * {
    cursor: default !important;
    pointer-events: none;
    user-select: none;
}
</style>
