
import OJ7060S from "@/service/OJ7060Service";
import ZFunc from "@/service/ZFunc";
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ7061F extends Vue {
    ZF: ZFunc = new ZFunc();
    OJ7060S: OJ7060S = new OJ7060S();

    mKengenList: any[] = [];
    mShosaiData: any = {};

    mAREAKY: any = 0;        // エリア
    mMODEXX: number = zCONST.cMODE_DISP;
    mUSERKY: number = 0;
    mTEKEKY: number = 0;
    mDispDetail: boolean = false;

    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt: number = 0;

    $cookies: any;
    onSuccess = (data: any) => { console.log(data); /* ここに返却時処理を書く */};

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mMODEXX = data.MODEXX;
        this.mUSERKY = data.USERKY;
        this.mTEKEKY = data.TEKEKY;

        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;
        // 初期データ取得
        this.fncGetShosai();
    }

    // 閉じるボタン
    fncClose() {
        this.mMessage = [];
        this.mDispDetail = false;
    }

    // 中止ボタン
    fncCancel() {
        this.mMODEXX = zCONST.cMODE_DISP;
        // 初期データ取得
        this.fncGetShosai();
    }

    // 修正ボタン
    fncUpdate() {
        this.mMODEXX = zCONST.cMODE_UPD;
    }

    // 登録ボタン
    fncDBToroku() {
        if (this.fncFldCheck() == false){
                return;
        }
        if (confirm("登録します。よろしいですか?") == true) {
            let wParam = { 
                 MODEXX: this.mMODEXX
                ,shosaiData: this.mShosaiData
            };
            // 登録処理
            this.OJ7060S.fncDBToroku(wParam).then((data: any) => {
                alert("登録しました。");
                // ダイアログを閉じる
                this.mDispDetail = false;
                // 成功後の後処理を行う。
                this.onSuccess(data);
            });
        }
    }

    // 削除ボタン
    fncDBDelete() {
        if (confirm("削除します。よろしいですか?") == true) {
            // 削除処理
            this.OJ7060S.fncDelete(this.mShosaiData.TEKEKY).then((data: any) => {
                alert("削除しました。");
                // ダイアログを閉じる
                this.mDispDetail = false;
                // 成功後の後処理を行う。
                this.onSuccess(data);
            });
        }
    }

    // 初期データ取得
    fncGetShosai(){
        let wTeikeiParam: {TEKEKY?: number, MODEXX?: number} = {};
        wTeikeiParam.TEKEKY = this.mTEKEKY;
        wTeikeiParam.MODEXX = this.mMODEXX;
        this.OJ7060S.fncGetShosai(wTeikeiParam).then((data: any) => {
            this.mShosaiData = data.shosaiData;
        });
    }

     // 入力項目チェック
    fncFldCheck() {
        this.mMessage = [];

        if (this.mShosaiData.DSPJUN < 1 || this.mShosaiData.DSPJUN > 9999) {
            this.mMessage.push({severity: "warn", content: "表示順は1～9999を入力してください", id: this.mMessageCnt++});
        }
        if (String(this.mShosaiData.TEKEBU).trim() == "") {
            this.mMessage.push({severity: "warn", content: "定型文を入力してください", id: this.mMessageCnt++});
        }
        if(this.mMessage.length > 0){
            return false;
        }else{
            return true;
        }
    }
}
