import repository from "@/repository/Repository";
import FileSaver from "file-saver";

export default class OJ9010Service {
	// ログイン情報取得
	fncGetUserInfo(pParam: any){
		return repository.post("/api/OJ0000C/GetUserInfo", pParam).then((res: any) => res.data);
	}
	// 一覧画面初期表示
	fncInit(pParam: any) {
		return repository.post("/api/OJ9010C/GetInit", pParam).then((res: any) => res.data)
	}
	// 一覧表示
	fncGetList(pParam: any) {
		return repository.post("/api/OJ9010C/GetList", pParam).then((res: any) => res.data)
	}
	// おくやみCSV作成
	fncDownload(pParam: any) {
        return repository
            .post("/api/OJ9010C/CsvDownload", pParam, {
                responseType: "blob",
            })
            .then((res: any) => {
                FileSaver.saveAs(res.data, pParam.FILENM);
                return true;
            })
            .catch(() => {
                alert("ダウンロードに失敗しました。");
                return false;
            });
    }
    // おくやみデータの存在チェック
    fncDBCheck(pParam: any) {
        return repository
            .post("/api/OJ9010C/DBCheck", pParam)
            .then((res: any) => res.data);
    }
}