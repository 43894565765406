import moment from "moment";

export default class ZFunc {
    // 文字列処理
    // zIsDate：日付判断
    zIsDate(pTarget: string): boolean {
        // 空文字は無視
        if (pTarget == "") {
            return true;
        }
        // 年/月/日の形式のみ許容する
        if (!pTarget.match(/^\d{4}\/\d{1,2}\/\d{1,2}$/)) {
            return false;
        }

        // 日付変換された日付が入力値と同じ事を確認
        // new Date()の引数に不正な日付が入力された場合、相当する日付に変換されてしまうため
        // 
        let date: Date = new Date(pTarget);
        if (date.getFullYear() != Number(pTarget.split("/")[0])
            || date.getMonth() != Number(pTarget.split("/")[1]) - 1
            || date.getDate() != Number(pTarget.split("/")[2])
        ) {
            return false;
        }

        return true;
    }

    // バイト数取得
    zByteLength(pStr: string) {
        let wResult = 0;
        for (let i = 0; i < pStr.length; i++) {
            let wChar = pStr.charCodeAt(i);
            if ((wChar >= 0x00 && wChar < 0x81) ||
                (wChar === 0xf8f0) ||
                (wChar >= 0xff61 && wChar < 0xffa0) ||
                (wChar >= 0xf8f1 && wChar < 0xf8f4)) {
                //半角文字の場合は1を加算
                wResult += 1;
            } else {
                //それ以外の文字の場合は2を加算
                wResult += 2;
            }
        }
        //結果を返す
        return wResult;
    }

    // maxLengthバイト数制限
    zByteMaxLength(pString: string | null, pMaxLength: number): string {
        let wResult: string = String(pString);

        while (this.zByteLength(wResult) > pMaxLength) {
            wResult = wResult.substring(0, wResult.length - 1);
        }
        return wResult;
    }

    // 数値処理
    // zInt：切り捨て
    zInt(pTarget: number, pKeta: number): number {
        return Math.ceil(pTarget * Math.pow(10, pKeta)) / Math.pow(10, pKeta);
    }

    // zCeil：切り上げ
    zCeil(pTarget: number, pKeta: number): number {
        return Math.floor(pTarget * Math.pow(10, pKeta)) / Math.pow(10, pKeta);
    }

    // zRound：四捨五入
    zRound(pTarget: number, pKeta: number): number {
        return Math.round(pTarget * Math.pow(10, pKeta)) / Math.pow(10, pKeta);
    }

    // 日付処理
    // z1stDay：月初日付の取得
    z1stDay(pTarget: Date): Date {
        return moment(moment(pTarget).format("YYYY/MM/01")).toDate();
    }

    // zLastDay：月末日付の取得
    zLastDay(pTarget: Date): Date {
        return moment(moment(pTarget).add(1, 'M').format("YYYY/MM/01")).add(-1, 'd').toDate();
    }

    // zTxt2Dt：文字列->DateTime型
    zTxt2Dt(pTarget: string): Date {
        let wTxt: string = pTarget.trim();
        let wDtStr: string = wTxt.substring(0, 4) + "/" + wTxt.substring(4, 6) + "/" + wTxt.substring(6, 8);
        if (this.zIsDate(wDtStr) == true) {
            return moment(wDtStr).toDate();
        }
        else {
            return new Date(-8640000000000000);
        }
    }

    // zDt2Txt：DateTime型->文字列
    zDt2Txt(pTarget: Date, pKugiriFlg: boolean): string {
        let wKugiri: string = "";
        if (pKugiriFlg == true) {
            wKugiri = "/";
        }

        let wDate: string = pTarget.getFullYear().toString() + wKugiri +
            (pTarget.getMonth() + 1).toString().padStart(2, '0') + wKugiri +
            pTarget.getDay().toString().padStart(2, '0');
        return wDate;
    }

    // zTxt2DtLong：文字列->DateTime型
    zTxt2DtLong(pTarget: string): Date {
        let wTxt: string = pTarget;
        let wDtStr: string = wTxt.substring(0, 4) + "/" + wTxt.substring(4, 2) + "/" + wTxt.substring(6, 2)
            + " " + wTxt.substring(8, 2) + ":" + wTxt.substring(10, 2) + ":" + wTxt.substring(12, 2);
        if (this.zIsDate(wDtStr) == true) {
            return moment(wDtStr).toDate();
        }
        else {
            return new Date(-8640000000000000);
        }

    }

    // zStrDtToSlash：yyyyMMddをyyyy/MM/ddに変換する
    zStrDtToSlash(pTarget: string): string {
        if (String(pTarget).trim() === "" || pTarget == null || pTarget.length == 0) {
            return "";
        }
        return pTarget.substring(0, 4) + "/" + pTarget.substring(4, 6) + "/" + pTarget.substring(6, 8);
    }

    // zSlashToStrDt：yyyy/MM/ddをyyyyMMddに変換する
    zSlashToStrDt(pTarget: string): string {
        return pTarget.replace("/", "");
    }

    // zStrDtToSlashMMDD：yyyyMMddをMM/ddに変換する
    zStrDtToSlashMMDD(pTarget: string): string {
        return String(this.zStrDtToSlash(pTarget)).substring(5, 10);
    }

    // zStrDtToNengapi：yyyyMMddをyyyy年MM月dd日に変換する
    zStrDtToNengapi(pTarget: string): string {
        if (
            String(pTarget).trim() === "" ||
            pTarget == null ||
            pTarget.length == 0
        ) {
            return "";
        }
        return (
            pTarget.substring(0, 4) +
            "年" +
            pTarget.substring(4, 6) +
            "月" +
            pTarget.substring(6, 8) +
            "日"
        );
    }

    // 画像ファイルか判定
    zIsImageFile(pImageFile: any): boolean {
        if (pImageFile == null) {
            return false;
        }
        if (pImageFile.type.indexOf("image/") != -1) {
            return true;
        }
        else {
            return false;
        }
    }
}