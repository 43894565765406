import { createRouter, createWebHashHistory } from 'vue-router';
import AuthService from "@/auth/AuthService";
import OJ0030Service from './service/OJ0030Service';

const AuthS: AuthService = new AuthService();
const OJ0030S: OJ0030Service = new OJ0030Service();

const routes = [
    {
        path: "/login",
        name: "LOGIN",
        component: () => import("@/auth/Login.vue"),
    },
    {
        path: "/reset",
        name: "RESET",
        component: () => import("@/auth/Reset.vue"),
    },
    {
        path: '/',
        name: '',
        component: () => import('@/App.vue'),
        children: [
            {
                //おくやみ 一覧画面
                path: '/OJ1010F',
                name: 'OJ1010F',
                component: () => import('@/pages/OJ1010/OJ1010F.vue'),
            },
            {
                //おくやみ 登録画面
                path: '/OJ1011F',
                name: 'OJ1011F',
                component: () => import('@/pages/OJ1010/OJ1011F.vue'),
            },
            {
                //おくやみ 確認画面
                path: '/OJ1012F',
                name: 'OJ1012F',
                component: () => import('@/pages/OJ1010/OJ1012F.vue'),
            },
            {
                //おくやみ 新聞社用PDF出力
                path: '/OJ1013F',
                name: 'OJ1013F',
                component: () => import('@/pages/OJ1010/OJ1013F.vue'),
            },
            {
                //おくやみ 同意書PDF出力
                path: '/OJ1014F',
                name: 'OJ1014F',
                component: () => import('@/pages/OJ1010/OJ1014F.vue'),
            },
            {
                //斎場マスタ管理 一覧画面
                path: '/OJ7040F',
                name: 'OJ7040F',
                component: () => import('@/pages/OJ7040/OJ7040F.vue'),
            },
            {
                //斎場マスタ管理 登録画面
                path: '/OJ7041F',
                name: 'OJ7041F',
                component: () => import('@/pages/OJ7040/OJ7041F.vue'),
            },
            {
                //斎場マスタ 参照画面
                path: '/OJ7045F',
                name: 'OJ7045F',
                component: () => import('@/pages/OJ7040/OJ7045F.vue'),
            },
            {
                //定型文マスタ保守 一覧画面
                path: '/OJ7060F',
                name: 'OJ7060F',
                component: () => import('@/pages/OJ7060/OJ7060F.vue'),
            },
            {
                //定型文マスタ保守 登録画面
                path: '/OJ7061F',
                name: 'OJ7061F',
                component: () => import('@/pages/OJ7060/OJ7061F.vue'),
            },
            {
                //ユーザマスタ管理 一覧画面
                path: '/OJ7090F',
                name: 'OJ7090F',
                component: () => import('@/pages/OJ7090/OJ7090F.vue'),
            },
            {
                //ユーザマスタ管理 登録画面
                path: '/OJ7091F',
                name: 'OJ7091F',
                component: () => import('@/pages/OJ7090/OJ7091F.vue'),
            },
            {
                //おくやみデータ出力画面
                path: '/OJ9010F',
                name: 'OJ9010F',
                component: () => import('@/pages/OJ9010/OJ9010F.vue'),
            },
            {
                //おくやみデータ出力画面(CSV)
                path: '/OJ9013F',
                name: 'OJ9013F',
                component: () => import('@/pages/OJ9010/OJ9013F.vue'),
            },
            {
                // マニュアルダウンロード
                path: '/OJ0030F',
                name: 'OJ0030F',
                component: () => import("@/pages/OJ0030/OJ0030F.vue"),
            },
        ]
    },
    // Not Found
    { path: "/:pathMatch(.*)", name: "ERR", redirect: "/" },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
