<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" v-show="true" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div>
          <div :class="pinClass" @click="fncClickPin()"/>
        </div>
        <a v-bind:href="keijibanUrl" target="_blank">
          <div class="layout-logo">
            <img class="layout-img" alt="Logo" :src="logo" />
          </div>
        </a>
        <AppProfile v-show="true" />
        <AppMenu
          :model="menu"
          @menuitem-click="onMenuItemClick"
          v-show="true"
        />
      </div>
    </transition>

    <div class="layout-main">
      <router-view />
    </div>
  </div>
</template>

<script>
import AppTopBar from "@/AppTopbar.vue";
import AppProfile from "@/AppProfile.vue";
import AppMenu from "@/AppMenu.vue";
import zCONST from "@/const";

export default {
  data() {
    return {
      layoutMode: "overlay",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menuFixed: true,
      menu: [
        {label: "おくやみ一覧", icon: "pi pi-fw pi-list", to: "/OJ1010F"}
      ],
      keijibanUrl: process.env.VUE_APP_KEIJIBAN_URL,
    };
  },
  created() {
    if (this.$cookies.get("KENGEN") == zCONST.cKENGEN_TOKUI_KANRI) {
      this.menu.push(
        {
          label: "斎場マスタ保守",
          icon: "pi pi-fw pi-building",
          to: "/OJ7040F",
        },
        {
          label: "定型文マスタ保守",
          icon: "pi pi-fw pi-pencil",
          to: "/OJ7060F",
        },
        {
          label: "ユーザーマスタ",
          icon: "pi pi-fw pi-user-edit",
          to: "/OJ7090F",
        },
        {
          label: "おくやみデータ出力",
          icon: "pi pi-fw pi-file",
          to: "/OJ9010F",
        }
      );
    } else {
      this.menu.push(
          {
            label: "定型文マスタ保守",
            icon: "pi pi-fw pi-pencil",
            to: "/OJ7060F",
          },
          {
          label: "おくやみデータ出力",
          icon: "pi pi-fw pi-file",
          to: "/OJ9010F",
          }
        );
      }

    this.menu.push(
      // {
      //   label: "マニュアルダウンロード",
      //   icon: "pi pi-fw pi-download",
      //   to: "/OJ0030F",
      // },
    )
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeMount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick && !this.menuFixed) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      this.menuFixed = false;

      this.mobileMenuActive = !this.mobileMenuActive;
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items&& !this.menuFixed) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) {
        element.classList.add(className);
      } else {
        element.className += " " + className;
      }
    },
    removeClass(element, className) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      return this.mobileMenuActive || this.menuFixed;
    },
    handleResize() {
      // 画面サイズ変更時イベント
      this.overlayMenuActive = false;
      this.mobileMenuActive = false;
      this.staticMenuInactive = false;
    },
    fncClickPin() {
      this.menuFixed = !this.menuFixed;
    }
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "menu-fixed": this.menuFixed,
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
        "no-scrollbar",
      ];
    },
    logo() {
      return "assets/layout/images/logo/logo.png";
    },
    pinClass() {
      return [
        "pin-svg",
        {
          "pin-light": this.menuFixed == true,
          "pin-dark": this.menuFixed == false,
        },
        "no-scrollbar",
      ];
    },

  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
.layout-logo {
  margin-top: 0px !important;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  border-radius: 10px;
}
@media (min-width: 1025px) {
  .layout-wrapper {
    &.menu-fixed {
      .layout-topbar {
          left: 250px !important;
      }
      .layout-topbar  {
          left: 250px !important;
      }
      .layout-sidebar {
          left: 0px !important;
      }
      .layout-main, .layout-footer {
          margin-left: 250px !important;
      }
    }
  }
}
@media (max-width: 1024px) {
  .layout-wrapper {
    &.menu-fixed {
      .layout-topbar {
          left: 250px !important;
      }
      .layout-topbar  {
          left: 0px !important;
      }
      .layout-sidebar {
          left: 0px !important;
      }
      .layout-main, .layout-footer {
          margin-left: 250px !important;
      }
    }
  }
}
@media print {
  .layout-wrapper {
    &.menu-fixed {
      .layout-main, .layout-footer {
          margin-left: 0px !important;
      }
    }
  }
}
.pin-svg {
  margin:3px 3px 3px auto;
  width: 20px;
  height: 20px;
}
.pin-light {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMTUuMDAwMDAwcHQiIGhlaWdodD0iMTUuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCA4MDQuMDAwMDAwIDEyODAuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+CjxtZXRhZGF0YT4KQ3JlYXRlZCBieSBwb3RyYWNlIDEuMTUsIHdyaXR0ZW4gYnkgUGV0ZXIgU2VsaW5nZXIgMjAwMS0yMDE3CjwvbWV0YWRhdGE+CjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLDEyODAuMDAwMDAwKSBzY2FsZSgwLjEwMDAwMCwtMC4xMDAwMDApIgpmaWxsPSIjRkZGRkZGIiBzdHJva2U9Im5vbmUiPgo8cGF0aCBkPSJNNDE5NSAxMjc3MyBjLTI4MiAtMTQgLTU3MCAtODYgLTc0MCAtMTg2IC0xNDYgLTg1IC0yNTAgLTIyNyAtMjc0Ci0zNzQgLTYgLTM3IC0yMyAtNDEyIC0zNyAtODMzIGwtMjUgLTc2NSAtNDY2IC0xNDAxIC00NjcgLTE0MDEgLTEyMSAtMTIKYy0zNjIgLTM1IC02NjEgLTE2MiAtOTE4IC0zODkgLTI0NiAtMjE3IC00MDUgLTQ3NiAtNDYyIC03NTIgLTI0IC0xMTEgLTI0Ci0zMjMgLTIgLTQ0MCA5OCAtNTA2IDUwNSAtMTAyNSAxMTI3IC0xNDM4IDgwIC01MyAxNDggLTk5IDE1MyAtMTAyIDQgLTMgLTUxCi0yMDQgLTEyMiAtNDQ2IC03MSAtMjQxIC0yMzggLTgxMCAtMzcxIC0xMjY0IC0xMzMgLTQ1NCAtMzQyIC0xMTY3IC00NjUKLTE1ODUgLTEyMyAtNDE4IC0yNTMgLTg2MyAtMjkwIC05ODkgLTU2IC0xOTEgLTY1IC0yMzAgLTU0IC0yNDEgMTkgLTE3IDUyCi0yNiA2NSAtMTcgNSA0IDIwMyA0MTYgNDM5IDkxNyAyMzUgNTAxIDYxOSAxMzE1IDg1MiAxODEwIDIzNCA0OTUgNDgzIDEwMjUKNTU1IDExNzggMTA1IDIyMiAxMzQgMjc3IDE0NyAyNzEgNzUgLTMwIDMwNyAtMTAxIDQzMiAtMTMzIDQzOCAtMTExIDg1MiAtMTMyCjExOTcgLTYxIDQ4OCAxMDEgODI0IDM4MyA5NjYgODExIDE2MCA0ODIgOTIgOTgzIC0xOTAgMTQwNSBsLTY0IDk3IDE4IDMxIGMzMQo1MSAxNDY5IDIyODQgMTUzMCAyMzc2IDU0IDgwIDg5IDExMyA2MTUgNTY2IDMwNyAyNjUgNTgxIDUwMyA2MDggNTMwIDE5OCAxOTUKMjA5IDQ3MiAzNCA4MzkgLTE5OCA0MTIgLTYxNiA4NDkgLTExNDkgMTE5OSAtMzUwIDIzMCAtODE3IDQ1NiAtMTIwNiA1ODUKLTQ2OSAxNTYgLTkzOSAyMzIgLTEzMTUgMjE0eiIvPgo8L2c+Cjwvc3ZnPgo=");
}
.pin-dark {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMTUuMDAwMDAwcHQiIGhlaWdodD0iMTUuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCA4MDQuMDAwMDAwIDEyODAuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+CjxtZXRhZGF0YT4KQ3JlYXRlZCBieSBwb3RyYWNlIDEuMTUsIHdyaXR0ZW4gYnkgUGV0ZXIgU2VsaW5nZXIgMjAwMS0yMDE3CjwvbWV0YWRhdGE+CjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLDEyODAuMDAwMDAwKSBzY2FsZSgwLjEwMDAwMCwtMC4xMDAwMDApIgpmaWxsPSIjOTk5OTk5IiBzdHJva2U9Im5vbmUiPgo8cGF0aCBkPSJNNDE5NSAxMjc3MyBjLTI4MiAtMTQgLTU3MCAtODYgLTc0MCAtMTg2IC0xNDYgLTg1IC0yNTAgLTIyNyAtMjc0Ci0zNzQgLTYgLTM3IC0yMyAtNDEyIC0zNyAtODMzIGwtMjUgLTc2NSAtNDY2IC0xNDAxIC00NjcgLTE0MDEgLTEyMSAtMTIKYy0zNjIgLTM1IC02NjEgLTE2MiAtOTE4IC0zODkgLTI0NiAtMjE3IC00MDUgLTQ3NiAtNDYyIC03NTIgLTI0IC0xMTEgLTI0Ci0zMjMgLTIgLTQ0MCA5OCAtNTA2IDUwNSAtMTAyNSAxMTI3IC0xNDM4IDgwIC01MyAxNDggLTk5IDE1MyAtMTAyIDQgLTMgLTUxCi0yMDQgLTEyMiAtNDQ2IC03MSAtMjQxIC0yMzggLTgxMCAtMzcxIC0xMjY0IC0xMzMgLTQ1NCAtMzQyIC0xMTY3IC00NjUKLTE1ODUgLTEyMyAtNDE4IC0yNTMgLTg2MyAtMjkwIC05ODkgLTU2IC0xOTEgLTY1IC0yMzAgLTU0IC0yNDEgMTkgLTE3IDUyCi0yNiA2NSAtMTcgNSA0IDIwMyA0MTYgNDM5IDkxNyAyMzUgNTAxIDYxOSAxMzE1IDg1MiAxODEwIDIzNCA0OTUgNDgzIDEwMjUKNTU1IDExNzggMTA1IDIyMiAxMzQgMjc3IDE0NyAyNzEgNzUgLTMwIDMwNyAtMTAxIDQzMiAtMTMzIDQzOCAtMTExIDg1MiAtMTMyCjExOTcgLTYxIDQ4OCAxMDEgODI0IDM4MyA5NjYgODExIDE2MCA0ODIgOTIgOTgzIC0xOTAgMTQwNSBsLTY0IDk3IDE4IDMxIGMzMQo1MSAxNDY5IDIyODQgMTUzMCAyMzc2IDU0IDgwIDg5IDExMyA2MTUgNTY2IDMwNyAyNjUgNTgxIDUwMyA2MDggNTMwIDE5OCAxOTUKMjA5IDQ3MiAzNCA4MzkgLTE5OCA0MTIgLTYxNiA4NDkgLTExNDkgMTE5OSAtMzUwIDIzMCAtODE3IDQ1NiAtMTIwNiA1ODUKLTQ2OSAxNTYgLTkzOSAyMzIgLTEzMTUgMjE0eiIvPgo8L2c+Cjwvc3ZnPgo=");
}
.no-scrollbar {
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
}
.no-scrollbar::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display:none;
}
.layout-img {
  width: 100%;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10px;
  transition: border-color 0.2s;
} :hover {
  border-color: rgb(200, 180, 190)
}
</style>
