
import { Vue } from "vue-class-component";

export default class AppProfile extends Vue {
	$cookies: any;
	expanded: boolean = false;

	fncGetUserName() {
		return this.$cookies.get("USERNM");
	}

	fncUserNameOnClick(event: Event) {
		this.expanded = !this.expanded;
		event.preventDefault();
	}

	fncLogoutOnClick() {
		this.$cookies.remove("USERKY");
		this.$cookies.remove("KENGEN");
		this.$cookies.remove("TOKENX");
		this.$cookies.remove("USERNM");
		this.$router.push({ name: "LOGIN" });
	}
}
