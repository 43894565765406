import repository from "@/repository/Repository";

export default class OJ7090Service {
	// ログイン情報取得
	fncGetUserInfo(pParam: any){
		return repository.post("/api/OJ0000C/GetUserInfo", pParam).then((res: any) => res.data);
	}
	// 一覧画面初期表示
	fncInit(pParam: any) {
		return repository.post("/api/OJ7090C/GetInit", pParam).then((res: any) => res.data)
	}
	// ユーザリスト取得
	fncGetList(pParam: any) {
		return repository.post("/api/OJ7090C/GetList", pParam).then((res: any) => res.data)
	}
	// ユーザ情報取得
	fncGetShosai(pParam: any) {
		return repository.post("/api/OJ7090C/GetShosai", pParam).then((res: any) => res.data)
	}
	// ユーザ情報登録
	fncDBToroku(pParam: any) {
		return repository.post("/api/OJ7090C/DBToroku", pParam).then((res: any) => res.data)
	}
	// ユーザ情報削除
	fncDeleteUserms(pParam: any) {
		return repository.post("/api/OJ7090C/DBDelete", pParam).then((res: any) => res.data)
	}
	// パスワードリセット
	fncReset(pParam: any){
		return repository.post("/api/ZAuth/Reset", pParam).then((res: any) => res.data);
	}
}