import repository from "@/repository/Repository";
import FileSaver from "file-saver";

export default class OJ1010Service {
    // ログイン情報取得
    fncGetUserInfo(pParam: any){
        return repository.post("/api/OJ0000C/GetUserInfo", pParam).then((res: any) => res.data);
    }
	// 登録画面初期表示
	fncInit(pParam: any) {
		return repository.post("/api/OJ1010C/GetInit", pParam).then((res: any) => res.data)
	}
	// おくやみ一覧取得
	fncGetList(pParam: any) {
		return repository.post("/api/OJ1010C/GetList", pParam).then((res: any) => res.data)
	}
	// おくやみ登録取得
	fncGetShosai(pParam: any) {
		return repository.post("/api/OJ1010C/GetShosai", pParam).then((res: any) => res.data)
	}
	// おくやみ情報登録
	fncDBToroku(pParam: any) {
		return repository.post("/api/OJ1010C/DBToroku", pParam).then((res: any) => res.data)
	}
	// おくやみ情報削除
	fncDBDelete(pParam: any) {
		return repository.post("/api/OJ1010C/DBDelete", pParam).then((res: any) => res.data)
	}
	// HTML取得
	fncGetHtml(pParam: any) {
		return repository.post("/api/OJ8010C/GetHtml", pParam).then((res: any) => res.data)
	}
	// HTML作成(ローカル)
	fncPageCreate(pParam: any) {
		return repository.post("/api/OJ8010C/PageCreate", pParam).then((res: any) => res.data)
	}	
    // HTMLアップロード
    fncPageUpload(pParam: any) {
        return repository.post("/api/OJ8010C/PageUpload", pParam).then((res: any) => res.data)
	}
	// 新聞社用PDF
    fncExport(pParam: any) {
        return repository
            .post("/api/OJ1010C/Export", pParam, {
                responseType: "blob",
                timeout: 0,
            })
            .then((response: any) => {
                const userAgent = window.navigator.userAgent.toLowerCase();

                if (response.headers["content-type"] == "application/pdf") {
                    const wUrl = window.URL.createObjectURL(response.data);

                    if (userAgent.indexOf("chrome") != -1) {
                        window.open(wUrl);
                    } else if (userAgent.indexOf("safari") != -1) {
                        window.open(wUrl, "_self");
                    } else {
                        window.open(wUrl);
                    }
                } else {
                    FileSaver.saveAs(response.data, pParam.FILENM);
                }
            })
            .catch(() => {
                alert("ファイルのダウンロードが失敗しました。");
                return false;
            });
    }
    // 同意書PDF
    fncExportDouisho(pParam: any) {
        return repository
            .post("/api/OJ1010C/ExportDouisho", pParam, {
                responseType: "blob",
                timeout: 0,
            })
            .then((response: any) => {
                const userAgent = window.navigator.userAgent.toLowerCase();

                if (response.headers["content-type"] == "application/pdf") {
                    const wUrl = window.URL.createObjectURL(response.data);

                    if (userAgent.indexOf("chrome") != -1) {
                        window.open(wUrl);
                    } else if (userAgent.indexOf("safari") != -1) {
                        window.open(wUrl, "_self");
                    } else {
                        window.open(wUrl);
                    }
                } else {
                    FileSaver.saveAs(response.data, pParam.FILENM);
                }
            })
            .catch(() => {
                alert("ファイルのダウンロードが失敗しました。");
                return false;
            });
    }
}