<template>
  <div class="p-component p-inputwrapper time-picker">
    <input type="time" :value="fncConvertTime" :disabled="disabled" :readonly="readonly" :class="['p-inputtext p-component input-timepicker', {'p-filled': fncFilled}, {'picker-hide': pickerHide }]"
     @blur="fncOnBlur" ref="TimePicker" :max="max" :min="min" @keydown="fncKeyDown($event)" />
    <i v-if="showClear && modelValue != null" class="p-timepicker-clear-icon pi" @click="fncOnClearClick()"></i>
  </div>
</template>

<script>
import moment from "moment";
import ZFunc from "@/service/ZFunc";

export default {
emits: ["update:modelValue"],
props: {
  modelValue: null,
  showClear: { type: Boolean, default: false},
  disabled: {type: Boolean, default: false},
  readonly: {type: Boolean, default: false},
  max: {type: String, default: ""},
  min: {type: String, default: ""},
  step: {type: Number, default: 1},
  stepMode: {type: String, default: "down"},
  pickerHide: { type: Boolean, default: false},
},
ZF: null,
mFORXIN: "",
mFORDSP: "",

created() {
  this.ZF = new ZFunc();
},
methods: {
  // TimePickerを離れた際の処理
  fncOnBlur(event) {
    let wRESULT = "";
    if(event.target.value.length <= 0) {
      wRESULT = "";
      event.target.value = "";
    } else {
      let wVALUTM = moment(event.target.value + ":00", "HH:mm:ss");
      //wRESULT = wVALUTM.format("HH:mm");
      wRESULT = this.fncGetFormat(wVALUTM);
      event.target.value = wRESULT + ":00";
    }

    this.$emit("update:modelValue", wRESULT);
  },
  fncOnClearClick() {
    this.$refs.DatePicker.value = "";
    this.$emit("update:modelValue", "");
  },
  fncGetFormat(wValue) {
    let wMinute = wValue.minute();
    let wHour = wValue.hour();
    if (wMinute % this.step > 0) {
      if (this.stepMode == "up") {
        wMinute = wMinute + this.step;
      }
      wHour = wHour + Math.floor(wMinute / 60);
      wMinute = Math.floor(wMinute % 60);
    }
    wMinute = Math.floor((wMinute / this.step)) * this.step;
    return ("00" + wHour).slice(-2) + ":" +  ("00" + wMinute).slice(-2);
  },
  fncKeyDown(wEvent) {
    if (this.readonly == true) {
      wEvent.preventDefault();
    }
  },
},
computed: {
  fncFilled() {
    return (this.modelValue != null && this.modelValue.toString().length > 0);
  },
  // 表示日付
  fncConvertTime() {
    let wRESULT = "";
    if (!this.modelValue || this.modelValue.length <= 0) {
      wRESULT = "";
    } else {
      let wVALUTM = moment(this.modelValue + ":00", "HH:mm:ss");
      wRESULT = this.fncGetFormat(wVALUTM) + ":00";
    }
    return wRESULT;
  },
},
};
</script>

<style lang="scss">
input:invalid {
background-color: rgb(255, 192, 192) !important;
}

input:valid {
background-color: transparent;

}
.time-picker {
position:relative;
  
::-webkit-calendar-picker-indicator { 
  cursor: pointer;
  border: none;
  padding-right: 0.1em;
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%236c757d%22%20d%3D%22M11.99%202C6.47%202%202%206.48%202%2012s4.47%2010%209.99%2010C17.52%2022%2022%2017.52%2022%2012S17.52%202%2011.99%202zM12%2020c-4.42%200-8-3.58-8-8s3.58-8%208-8%208%203.58%208%208-3.58%208-8%208z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%20%2F%3E%3Cpath%20fill%3D%22%236c757d%22%20d%3D%22M12.5%207H11v6l5.25%203.15.75-1.23-4.5-2.67z%22%2F%3E%3C%2Fsvg%3E');
}

.picker-hide::-webkit-calendar-picker-indicator {
  display: none;
}

.input-timepicker {
  height: 35px;
  background-color:transparent;
  color:inherit;
}

.p-timepicker-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -.5rem;
  color: #6c757d;
  right: 2.457rem;
}
}
</style>