
import { Vue } from "vue-class-component";
import OJ9010S from "@/service/OJ9010Service";
import ZFunc from "@/service/ZFunc";
import zCONST from "@/const";

export default class OJ3013F extends Vue {
    OJ9010S: OJ9010S = new OJ9010S();
    ZF: ZFunc = new ZFunc();

    mDispDetail = false;

    // 変数定義
    mRefTOKUKY: any = 0;
    mRefTOROFR: any = "";
    mRefTOROTO: any = "";
    mFILENM: any = "";

    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt = 0;

    $cookies: any;

    onSuccess = (data: any) => {
        console.log(data); /* ここに返却時処理を書く */
    };

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mRefTOKUKY = data.TOKUKY;
        this.mRefTOROFR = data.TOROFR;
        this.mRefTOROTO = data.TOROTO;

        this.mFILENM = "おくやみデータ";        
        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;
        this.mMessage = [];
    }

    // CSV処理ボタン
    fncDownload() {
        if (this.fncFldCheck() == false) {
            return;
        }

        const wParam = {
            TOKUKY: this.mRefTOKUKY
            ,TOROFR: this.mRefTOROFR
            ,TOROTO: this.mRefTOROTO
            ,FILENM: this.mFILENM.replace(/\s+/g, "")+ ".csv"
        };
        if (this.fncFldCheck() == true) {
            this.mMessage = [];
            this.OJ9010S.fncDBCheck(wParam).then((data: any) => {
                if (data[zCONST.cKEY_RESULT] == true) {
                    this.OJ9010S.fncDownload(wParam).then(() => {});
                } else {
                    this.mMessage = data[zCONST.cKEY_MESSAGE];
                    window.scrollTo(0, 0);
                }
            });
        } else {
            window.scrollTo(0, 0);
        }
    }

    fncFldCheck() {
        this.mMessage = [];
        if (this.mFILENM == "") {
            this.mMessage.push({
                severity: "warn",
                content: "ファイル名" + zCONST.cERR_BLANK,
                id: this.mMessageCnt++,
            });
        }

        if (this.mMessage.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    // 閉じるボタン
    fncClose() {
        this.mDispDetail = false;
    }
}
