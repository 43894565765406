
import OJ1010S from "@/service/OJ1010Service";
import OJ7040S from "@/service/OJ7040Service";
import OJ7060S from "@/service/OJ7060Service";
import ZFunc from "@/service/ZFunc";
import { Vue } from "vue-class-component";
import zCONST from "@/const";
import { Core as YubinBangoCore } from "yubinbango-core2";
import { ref } from "vue";

export default class OJ1011F extends Vue {
    ZF: ZFunc = new ZFunc();
    OJ1010S: OJ1010S = new OJ1010S();   
    OJ7040S: OJ7040S = new OJ7040S();   
    OJ7060S: OJ7060S = new OJ7060S();   
    dialog: any = ref(null);
    mDispDetail: boolean = false;
    mAreaList: any[] = [];
    mShosaiData: any = {};
    mMODEXX: number = zCONST.cMODE_DISP;
    mUSERKY: number = 0;        //ユーザーキー
    mOKYAKY: number = 0;        //おくやみキー
    mTOKUKY: number = 0;        //得意先
    mAREAKY: number = 0;        //エリアキー
    mChikuList: any[] = [];     //地区リスト
    mTimeList: any[] = [];     //時間リスト
    mTekebuList: any[] = [];   //定型文リスト
    mYoshikiList: any[] = [];   //様式リスト
    mKOKAKB: string = "";       //公開
    mSearchZIPXCD: string = ""; //住所検索
    mTekebu: string = "";       //定型文
    mTekebuMsgfl: boolean = false;
    mBIKOUXMsg: string = "備考は500文字以内で入力してください。";
    mTUSAJYMsgfl: boolean = false;
    mKOSAJYMsgfl: boolean = false;
    mSOSAJYMsgfl: boolean = false;
    mSAIJYOMsg: string = "斎場を入力してください。";
    
    // エラーメッセージ
    mMessage: any[] = [];
    mMessageCnt: number = 0;

    mSearchMsg: any = "検索結果が見つかりません。";
    mSearchMsgfl: boolean = false;

    $cookies: any;

    onSuccess = (data: any) => { console.log(data); /* ここに返却時処理を書く */};

    // ダイアログの表示
    fncShow(data: any, onSuccess: (data: any) => void) {
        // 参照を切るため、それぞれ変数へ移す
        this.mMODEXX = data.MODEXX;
        this.mOKYAKY = data.OKYAKY;
        this.mUSERKY = this.$cookies.get("USERKY");

        //エラーメッセージ初期化
        this.mMessage = [];
        this.mMessageCnt = 0;

        // コールバックを保持
        this.onSuccess = onSuccess;
        // ダイアログを表示
        this.mDispDetail = true;
        // 初期データ取得
        this.fncInit();
    }

    // 初期表示処理
    fncInit(){
        //ログイン情報取得
        let wUSERKY = this.$cookies.get("USERKY");
        let wParam = { 
            USERKY: wUSERKY
        };
        this.OJ1010S.fncGetUserInfo(wParam).then((data: any) => {
            this.mAREAKY = data.AREAKY;
            this.mTOKUKY = data.TOKUKY;
            this.funcGetComboList();
            this.funcGetShosai();
            this.mSearchZIPXCD = "";
            this.mSearchMsgfl = false;
            this.mTekebu = "";
            this.mTekebuMsgfl = false;
            this.mTUSAJYMsgfl = false;
            this.mKOSAJYMsgfl = false;
            this.mSOSAJYMsgfl = false;
        });
    }

    // 閉じるボタン
    fncClose() {
        this.mSearchZIPXCD = "";
        this.mDispDetail = false;
    }

    // 登録ボタン
    fncDBToroku() {
        if (this.fncFldCheck() == false){
                return;
        }
            //登録パラメータ
            this.mShosaiData.USERKY = this.$cookies.get("USERKY");
            this.mShosaiData.TOKUKY = this.mTOKUKY;
            this.mShosaiData.AREAKY = this.mAREAKY;
            let wParam = { 
                shosaiData: this.mShosaiData
            };
            // 登録処理
            this.OJ1010S.fncDBToroku(wParam).then((data: any) => {
                //alert("登録しました。");
                // 成功後の後処理を行う。
                this.onSuccess(data);
                this.mOKYAKY = data.OKYAKY;
                this.mShosaiData.OKYAKY = this.mOKYAKY;
                // 詳細画面を新規モードで呼び出す
                const zZ1012F: any = this.$refs["OJ1012F"];
                //ダイアログを表示
                zZ1012F.fncShow(
                {
                    OKYAKY: this.mOKYAKY
                    ,TOKUKY: this.mTOKUKY
                    ,AREAKY: this.mAREAKY
                    ,USERKT: this.$cookies.get("USERKY")
                },
                () => {
                    location.reload();
                    this.mDispDetail = false;
                });
            });
    }

    // 削除ボタン
    fncDBDelete() {
        if (confirm("削除します。よろしいですか?") == true) {
            let wParam = {
            OKYAKY: this.mShosaiData.OKYAKY
            ,AREAKY: this.mAREAKY
            };
            // 登録処理
            this.OJ1010S.fncDBDelete(wParam).then((data: any) => {
                alert("削除しました。");
                // ダイアログを閉じる
                this.mDispDetail = false;
                // 成功後の後処理を行う。
                this.onSuccess(data);
            });
        }
    }

    // 郵便番号自動検索
    fncSearchYubin(){
        new YubinBangoCore(this.mSearchZIPXCD.replace("-",""),(data: any) =>
        {
            if(data.region != ""){
                this.mShosaiData.KJADDR = data.region + data.locality + data.street;
                this.mSearchMsgfl = false;
            }else{
                this.mShosaiData.KJADDR = "";
                this.mSearchMsgfl = true;
            }
        })
    }

    // 参照ボタン（斎場マスタ参照画面表示）
    fncSaijyoSansho(event:Event){
        // 参照画面を新規モードで呼び出す
        const OJ7045F: any = this.$refs["OJ7045F"];

        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let wBtnId = event.target.id;
        let wParam = { USERKY: this.mUSERKY };
        //ダイアログを表示
        OJ7045F.fncShow(wParam, (data: any) => {
            if(wBtnId == 'TUYABT'){
                this.mShosaiData.TUYASA = data.SAJYNM;
                this.mShosaiData.TUYABA = data.ADDRES;
                this.mShosaiData.TUYURL = data.MAPURL;
            }
            if(wBtnId == 'KOKUBT'){
                this.mShosaiData.KOKUSA = data.SAJYNM;
                this.mShosaiData.KOKUBA = data.ADDRES;
                this.mShosaiData.KOKURL = data.MAPURL;
            }
            if(wBtnId == 'SOGIBT'){
                this.mShosaiData.SOGISA = data.SAJYNM;
                this.mShosaiData.SOGIBA = data.ADDRES;
                this.mShosaiData.SOGURL = data.MAPURL;
            }
        });
    }

    // 故人住所ボタン
    fncKojin(event:Event){
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let wBtnId = event.target.id;
        let wRet = this.mShosaiData.KJADDR;
        if(wBtnId == 'TUYAB2'){
            this.mShosaiData.TUYASA = "";
            this.mShosaiData.TUYABA = wRet;
            this.mShosaiData.TUYURL = "";
        }
        if(wBtnId == 'KOKUB2'){
            this.mShosaiData.KOKUSA = "";
            this.mShosaiData.KOKUBA = wRet;
            this.mShosaiData.KOKURL = "";
        }
        if(wBtnId == 'SOGIB2'){
            this.mShosaiData.SOGISA = "";
            this.mShosaiData.SOGIBA = wRet;
            this.mShosaiData.SOGURL = "";
        }
    }

    // お通夜場所ボタン
    fncTuyaBasho(event:Event){
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let wBtnId = event.target.id;
        let wSaijo = this.mShosaiData.TUYASA;
        let wRet = this.mShosaiData.TUYABA;
        let wUrl = this.mShosaiData.TUYURL;
        if(wBtnId == 'KOKUB3'){
            this.mShosaiData.KOKUSA = wSaijo;
            this.mShosaiData.KOKUBA = wRet;
            this.mShosaiData.KOKURL = wUrl;
        }
        if(wBtnId == 'SOGIB3'){
            this.mShosaiData.SOGISA = wSaijo;
            this.mShosaiData.SOGIBA = wRet;
            this.mShosaiData.SOGURL = wUrl;
        }
    }

    //告別式場所ボタン
    fncKokuBasho(event:Event){
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let wBtnId = event.target.id;
        let wSaijo = this.mShosaiData.KOKUSA;
        let wRet = this.mShosaiData.KOKUBA;
        let wUrl = this.mShosaiData.KOKURL;
        if(wBtnId == 'SOGIB4'){
            this.mShosaiData.SOGISA = wSaijo;
            this.mShosaiData.SOGIBA = wRet;
            this.mShosaiData.SOGURL = wUrl;
        }
    }

     // マップ作成
    fncMapCreate(event:Event){
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let wBtnId = event.target.id;
        let wMap = 'http://maps.google.co.jp/maps?q='
        let wZoom = '&z=18';
        if(wBtnId == 'TUYSET'){
            if(this.mShosaiData.TUYABA == ''){
                this.mShosaiData.TUYURL = '';
            }
            else{
                this.mShosaiData.TUYURL = wMap + this.mShosaiData.TUYABA + wZoom;
            }
        }
        if(wBtnId == 'KOKSET'){
            if(this.mShosaiData.KOKUBA == ''){
                this.mShosaiData.KOKURL = '';
            }else{
                this.mShosaiData.KOKURL = wMap + this.mShosaiData.KOKUBA + wZoom;
            } 
        }
        if(wBtnId == 'SOGSET'){
            if(this.mShosaiData.SOGIBA == ''){
                this.mShosaiData.SOGURL = '';
            }else{
                this.mShosaiData.SOGURL = wMap + this.mShosaiData.SOGIBA + wZoom;
            }
        }
    }

    // マップの表示(別画面)
    fncMapShow(event:Event){
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let wBtnId = event.target.id;
        let wUrl = '';
        if(wBtnId == 'TUYMAP'){
            wUrl = this.mShosaiData.TUYURL;
        }
        if(wBtnId == 'KOKMAP'){
            wUrl = this.mShosaiData.KOKURL;
        }
        if(wBtnId == 'SOGMAP'){
            wUrl = this.mShosaiData.SOGURL;
        }
        if(wUrl != ''){
            window.open(wUrl, "_blank");
        }
    }

    //　画面コンボボックス設定
    funcGetComboList(){
        let wParam = { 
            AREAKY: this.mAREAKY,
            TOKUKY: this.mTOKUKY
        };
        this.OJ1010S.fncInit(wParam).then((data: any) => {
            this.mChikuList = data.ChikuList;
            this.mTimeList = data.TimeList;
            this.mTekebuList = data.TekebuList;
            this.mYoshikiList = data.YoshikiList;
        });
    }
    
    // 詳細データ取得
    funcGetShosai(){
        let wParam = { 
            OKYAKY: this.mOKYAKY
        };
        this.OJ1010S.fncGetShosai(wParam).then((data: any) => {
            this.mShosaiData = data.shosaiData;
            if(data.shosaiData.KOKAKB == 1)
            {
                this.mKOKAKB = "公開済み";
            }else{
                this.mKOKAKB = "未公開";
            }
        });
    }

    // 入力項目チェック
    fncFldCheck() {
        this.mMessage = [];
        if (String(this.mShosaiData.KJSENM).trim() + String(this.mShosaiData.KJMENM).trim() == "") {
            this.mMessage.push({severity: "warn", content: "【故人の情報】姓名を入力してください", id: this.mMessageCnt++});
        }
        if (String(this.mShosaiData.KJSEKN).trim() + String(this.mShosaiData.KJMEKN).trim() == "") {
            this.mMessage.push({severity: "warn", content: "【故人の情報】カナを入力してください", id: this.mMessageCnt++});
        }
        if (this.mShosaiData.NENREI > 127) {
            this.mMessage.push({severity: "warn", content: "年齢は0～127を入力してください", id: this.mMessageCnt++});
        }
        if(this.mShosaiData.BIKOUX.length > 500) {
            this.mMessage.push({severity: "warn", content: this.mBIKOUXMsg, id: this.mMessageCnt++});
        }
        if(this.mMessage.length > 0){
            this.dialog.container
                .querySelector(".p-dialog-content")
                .scrollTo(0, 0);
            return false;
        }else{
            return true;
        }
    }

    // 定型文挿入
    fncInsTeikei() {
        this.mShosaiData.BIKOUX += this.mTekebu;
    }

    // 定型文登録
    fncTeikeiToroku() {
        // 入力チェック
        this.mTekebuMsgfl = false;
        if(this.mShosaiData.BIKOUX.length > 500) {
            this.mTekebuMsgfl = true;
            return;
        }


        if (confirm("備考を定型文として登録します。よろしいですか?") == true) {
            //MODEは必ず新規
            //DSPJUNは0で渡して、サーバー側で得意先内のMAX＋1
            let shosaiData = {
                TEKEKY: 0,
                DSPJUN: 0,
                TEKEBU: this.mShosaiData.BIKOUX
            };

            let wParam = { 
                 MODEXX: zCONST.cMODE_ADD
                ,shosaiData: shosaiData
            };
            // 登録処理
            this.OJ7060S.fncDBToroku(wParam).then((data: any) => {
                alert("定型文を登録しました。");
                //選択候補再取得
                this.funcGetComboList();
            });
        }
    }

    // 斎場登録
    fncRegisterSAIJYO(event:Event) {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let wBtnId = event.target.id;
        this.mTUSAJYMsgfl = false;
        this.mKOSAJYMsgfl = false;
        this.mSOSAJYMsgfl = false;

        //入力チェック
        if(wBtnId == 'TUSAJY'){
            if(String(this.mShosaiData.TUYASA).trim() == "") {
                this.mTUSAJYMsgfl = true;
                return;
            }
        }
        else if(wBtnId == 'KOSAJY'){
            if(String(this.mShosaiData.KOKUSA).trim() == "") {
                this.mKOSAJYMsgfl = true;
                return;
            }
        }
        else if(wBtnId == 'SOSAJY'){
            if(String(this.mShosaiData.SOGISA).trim() == "") {
                this.mSOSAJYMsgfl = true;
                return;
            }
        }
        else {
            return;
        }

        if (confirm("斎場を登録します。よろしいですか?") == true) {
            let wSAJONM = '';
            let wADDRE1 = '';
            let wMAPURL = '';

            if(wBtnId == 'TUSAJY'){
                wSAJONM = this.mShosaiData.TUYASA;
                wADDRE1 = this.mShosaiData.TUYABA;
                wMAPURL = this.mShosaiData.TUYURL;
            }
            else if(wBtnId == 'KOSAJY'){
                wSAJONM = this.mShosaiData.KOKUSA;
                wADDRE1 = this.mShosaiData.KOKUBA;
                wMAPURL = this.mShosaiData.KOKURL;
            }
            else if(wBtnId == 'SOSAJY'){
                wSAJONM = this.mShosaiData.SOGISA;
                wADDRE1 = this.mShosaiData.SOGIBA;
                wMAPURL = this.mShosaiData.SOGURL;
            }
            else {
                return;
            }

            //MODEは必ず新規
            let shosaiData = {
                SAJYKY: 0,
                SAJYNM: wSAJONM,
                ADDRE1: wADDRE1,
                MAPURL: wMAPURL,
                ZIPXCD: '',
                ADDRE2: '',
                TELXNO: '',
                FAXXNO: '',
                HPADDR: '',
                BIKOUX: ''
            };
            let wParam = { 
                MODEXX: zCONST.cMODE_ADD
                ,shosaiData: shosaiData
            };
            
            // 登録処理
            this.OJ7040S.fncDBToroku(wParam).then((data: any) => {
                alert("斎場を登録しました。");
            });
        }
    }
}
