import repository from "@/repository/Repository";
import VueCookies from 'vue-cookies';

export default class OJ7090Service {
	fncLogin(pParam: any): Promise<any> {
		return repository.post("/api/ZAuth/LoginTokui", pParam).then(res => res.data);
	}
	// fncForgot(pParam: any): Promise<any>{
	// 	return repository.post("/api/ZAuth/UpdatePassword", pParam).then(res => res.data);
	// }

	fncUpdateToken(): Promise<boolean> {
		let cookies: any = VueCookies;
		let wUSERKY = cookies.get("USERKY");
		let wParam = { USERKY: wUSERKY };
		return repository.post("/api/ZAuth/UpdateToken", wParam).then((res) => {
			cookies.set("TOKENX", res.data["TOKENX"]);
			return true;
		}).catch(() => {
			return false;
		});
	}

	fncCheckKengen(pPROGID: string): Promise<boolean> {
		let cookies: any = VueCookies;
		let wUSERKY = cookies.get("USERKY");
		let wParam = { USERKY: wUSERKY, PROGID: pPROGID };
		return repository.post("/api/ZAuth/CheckKengen", wParam).then((res: any) => {
			if (res.data["result"] == false) {
				return false;
			}
			return true;
		}).catch(() => {
			return false;
		});		
	}

	// パスワードリセット
	fncReset(pParam: any): Promise<any> {
		return repository.post("/api/ZAuth/Reset", pParam).then((res: any) => res.data);
	}

	// リセットハッシュチェック
	fncCheckResetHash(pParam: any): Promise<any> {
		return repository.post("/api/ZAuth/CheckResetHash", pParam).then((res: any) => res.data);
	}
	
	// 得意先管理者ユーザーリセット
	fncResetUser(pParam: any): Promise<any> {
		return repository.post("/api/ZAuth/ResetUser", pParam).then((res: any) => res.data);
	}

}