import { createApp } from 'vue';
import { reactive } from 'vue';
import router from '@/router';
import VueCookies from 'vue-cookies';
import App from '@/App.vue';
import Index from '@/Index.vue';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

// componets
import zDatePicker from "@/components/zDatePicker/zDatePicker.vue";
import zDropdown from "@/components/zDropdown/zDropdown.vue";
import zMessage from "@/components/zMessage/zMessage.vue";
import zTimePicker from "@/components/zTimePicker/zTimePicker.vue";

////////////////
// form
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@fullcalendar/core/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "@fullcalendar/timegrid/main.min.css";
import "@/assets/layout/layout.scss";
import "@/assets/layout/flags/flags.css";
import "@/assets/layout/theme.css";
import "@/assets/layout/themeplus.css";
import "@/pages/css/common.scss";
import OJ1011F from '@/pages/OJ1010/OJ1011F.vue';
import OJ1012F from '@/pages/OJ1010/OJ1012F.vue';
import OJ1013F from '@/pages/OJ1010/OJ1013F.vue';
import OJ1014F from '@/pages/OJ1010/OJ1014F.vue';
import OJ7041F from '@/pages/OJ7040/OJ7041F.vue';
import OJ7045F from '@/pages/OJ7040/OJ7045F.vue';
import OJ7061F from '@/pages/OJ7060/OJ7061F.vue';
import OJ7091F from '@/pages/OJ7090/OJ7091F.vue';
import OJ9013F from '@/pages/OJ9010/OJ9013F.vue';

import AuthService from "@/auth/AuthService";

const AuthS: AuthService = new AuthService();

router.beforeEach(function (to: any, from: any, next: any) {
	window.scrollTo(0, 0);
	if (to.name == 'LOGIN' || to.name == 'RESET') {
		next();
		return;
	}

	AuthS.fncUpdateToken().then((res: boolean) => {
		if (res == true) {
			AuthS.fncCheckKengen(to.name).then((res: boolean) => {
				if (res == false) {
					// 無限リダイレクトを防ぐ
					if (to.name != 'OJ1010F') {
						next({name: 'OJ1010F'});
						return;
					} else {
						next();
						return;
					}
				} else {
					next();
					return;
				}
			});
		} else {
			next({name: 'LOGIN'});
			return;
		}
	});
});

const app = createApp(App);
const index = createApp(Index);

const cookies: any = VueCookies;

cookies.config("10y");

app.config.globalProperties.$cookies = cookies;
app.mount('#app');

index.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });
index.config.globalProperties.$primevue = reactive({ ripple: true });
index.config.globalProperties.$cookies = cookies;

index.use(ToastService);
index.use(router);

index.directive('tooltip', Tooltip);
index.directive('ripple', Ripple);
index.component('Accordion', Accordion);
index.component('AccordionTab', AccordionTab);
index.component('AutoComplete', AutoComplete);
index.component('Breadcrumb', Breadcrumb);
index.component('Button', Button);
index.component('Calendar', Calendar);
index.component('Card', Card);
index.component('Carousel', Carousel);
index.component('Chart', Chart);
index.component('Checkbox', Checkbox);
index.component('Chips', Chips);
index.component('ColorPicker', ColorPicker);
index.component('Column', Column);
index.component('ContextMenu', ContextMenu);
index.component('DataTable', DataTable);
index.component('DataView', DataView);
index.component('DataViewLayoutOptions', DataViewLayoutOptions);
index.component('Dialog', Dialog);
index.component('Dropdown', Dropdown);
index.component('Fieldset', Fieldset);
index.component('FileUpload', FileUpload);
index.component('FullCalendar', FullCalendar);
index.component('InlineMessage', InlineMessage);
index.component('Inplace', Inplace);
index.component('InputMask', InputMask);
index.component('InputNumber', InputNumber);
index.component('InputSwitch', InputSwitch);
index.component('InputText', InputText);
index.component('Galleria', Galleria);
index.component('Listbox', Listbox);
index.component('MegaMenu', MegaMenu);
index.component('Menu', Menu);
index.component('Menubar', Menubar);
index.component('Message', Message);
index.component('MultiSelect', MultiSelect);
index.component('OrderList', OrderList);
index.component('OrganizationChart', OrganizationChart);
index.component('OverlayPanel', OverlayPanel);
index.component('Paginator', Paginator);
index.component('Panel', Panel);
index.component('PanelMenu', PanelMenu);
index.component('Password', Password);
index.component('PickList', PickList);
index.component('ProgressBar', ProgressBar);
index.component('RadioButton', RadioButton);
index.component('Rating', Rating);
index.component('SelectButton', SelectButton);
index.component('Slider', Slider);
index.component('Sidebar', Sidebar);
index.component('SplitButton', SplitButton);
index.component('Steps', Steps);
index.component('TabMenu', TabMenu);
index.component('TabView', TabView);
index.component('TabPanel', TabPanel);
index.component('Textarea', Textarea);
index.component('TieredMenu', TieredMenu);
index.component('Toast', Toast);
index.component('Toolbar', Toolbar);
index.component('ToggleButton', ToggleButton);
index.component('Tree', Tree);
index.component('TreeTable', TreeTable);
index.component('TriStateCheckbox', TriStateCheckbox);
index.component('zDatePicker', zDatePicker);
index.component('zDropdown', zDropdown);
index.component('zTimePicker', zTimePicker);
index.component('zMessage',zMessage);
// form
index.component('OJ1011F', OJ1011F);
index.component('OJ1012F', OJ1012F);
index.component('OJ1013F', OJ1013F);
index.component('OJ1014F', OJ1014F);
index.component('OJ7041F', OJ7041F);
index.component('OJ7045F', OJ7045F);
index.component('OJ7061F', OJ7061F);
index.component('OJ7091F', OJ7091F);
index.component('OJ9013F', OJ9013F);


index.mount('#index');