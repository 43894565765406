import axios from "axios";
import router from "@/router";
import VueCookies from "vue-cookies";

const mCookies: any = VueCookies;
// process.env.VUE_APP_API_BASE_URLの中身は .env ファイル参照
// ビルドの時に mode を指定することで .env.~~~ を読み込むように変わる
// package.json 9行目あたりから参照
const ax = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    responseType: 'json',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

// 共通処理
ax.interceptors.request.use(
  function (request) {
    if (mCookies.isKey("TOKENX") == true) {
			if (request.headers != undefined) {
				request.headers["Authorization"] = "Bearer " + mCookies.get("TOKENX");
				request.headers["Userky"] = mCookies.get("USERKY");
			}
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ax.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // ネットワークエラー時はログインページへ
    if(error.message == 'Network Error') {
      router.push({ name: "LOGIN" });
    }

    // APIのセッションチェックエラー時のみ
    if (error.response.status == 401 && error.response.data == "session") {
      // ログインページへ
      mCookies.remove("USERKY");
      mCookies.remove("KENGEN");
      mCookies.remove("TOKENX");
      mCookies.remove("USERNM");
      router.push({ name: "LOGIN" });
    } else {
			if (error.response.data?.error) {
				alert(error.response.data?.error);
			} else {
				alert("サーバとの通信に失敗しました。");
			}
    }
  }
);

export default ax;