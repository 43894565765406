import repository from "@/repository/Repository";

export default class OJ7090Service {
	// ログイン情報取得
	fncGetUserInfo(pParam: any){
		return repository.post("/api/OJ0000C/GetUserInfo", pParam).then((res: any) => res.data);
	}
	// 一覧画面初期表示
	fncInit(pParam: any) {
		return repository.post("/api/OJ7040C/GetInit", pParam).then((res: any) => res.data)
	}
	// 斎場リスト取得
	fncGetList(pParam: any) {
		return repository.post("/api/OJ7040C/GetList", pParam).then((res: any) => res.data)
	}
	// 斎場マスタ取得
	fncGetShosai(pParam: any) {
		return repository.post("/api/OJ7040C/GetShosai", pParam).then((res: any) => res.data)
	}
	// 斎場マスタ登録
	fncDBToroku(pParam: any) {
		return repository.post("/api/OJ7040C/DBToroku", pParam).then((res: any) => res.data)
	}
	// 斎場マスタ削除
	fncDelete(pParam: any) {
		return repository.post("/api/OJ7040C/DBDelete", pParam).then((res: any) => res.data)
	}
	// 参照斎場リスト取得
	fncGetSanshoList(pParam: any) {
		return repository.post("/api/OJ7040C/GetSanshoList", pParam).then((res: any) => res.data)
	}
}